import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect, useRef } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Loader from "./Loader";
import DialogProducts from "./DialogProducts";
import { useTranslation } from "react-i18next";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SalesReturnConfirm from "./SalesReturnConfirm";
import DuplicateModal from "../modals/DuplicateModal";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function AllInvoices() {
  const navigate = useNavigate();
  const [openInvoiceProducts, setOpenInvoiceProducts] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [dupInvoice, setDupInvoice] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const { t, i18n } = useTranslation();
  const handleOpenInvoiceProducts = (value) => {
    setOpenInvoiceProducts(true);
    setSelectedItem(value);
  };

  const handleCloseInvoiceProducts = () => {
    setOpenInvoiceProducts(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const [openSalesConfirm, setOpenSalesConfirm] = useState(false);
  const [returnSale, setReturnSale] = useState(null);
  const OpenSalesModal = (id) => {
    setOpenSalesConfirm(true);
    setReturnSale(id);
  };
  const CloseSalesModal = () => {
    setOpenSalesConfirm(false);
  };

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelInvoice(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [delInvoice, setDelInvoice] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const [searchPhrase, setSearchPhrase] = useState("");


  const AppContext = useContext(AppStorage);

  
  useEffect(() => {
    if(AppContext?.userInfo.role &&  AppContext?.userInfo.role != "user") {
      setTableColumns(columns.concat(userColumn, actionColumn));
    } else {
      setTableColumns(columns.concat(actionColumn));
    }
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getData({ pagination, searchPhrase });
    }
  }, [AppContext?.userAuthToken, pagination?.current, AppContext?.userInfo, i18n.language]);

  useEffect(() => {
    if (AppContext?.userInfo?.role === "admin") {
      i18n.changeLanguage("en");
    }
  },[AppContext?.userAuthToken, pagination?.current, AppContext?.userInfo, i18n.language])
  
  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, searchPhrase, startDate, endDate });
    }
  }, [forceUpdate]);


  
  
  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      startDate: params?.dateStart || moment.utc(moment(startDate).format().substring(0, 10)).format(),
      endDate: params?.dateEnd || moment.utc(moment(endDate).format().substring(0, 10)).format(),
      searchPhrase: params.searchPhrase,
    };

    Request.get(`/getInvoice/${params.pagination?.current}`, paramsData)
      .then((result) => {
        setLoading(false);
        setInvoices(result.data["data"]);
        console.log(result.data["data"]);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const handleSelect = (date) => {
    const dateStart =  moment.utc(moment(date.selection.startDate).format().substring(0, 10)).format();
    const dateEnd = moment.utc(moment(date.selection.endDate).format().substring(0, 10)).format();
   
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    
    getData({ pagination, dateStart, dateEnd });
  };
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  
  };
  function searchChange(value) {
    setSearchPhrase(value)
    setPagination({ ...pagination, current: 1 });
    getData({ pagination: { ...pagination, current: 1 }, searchPhrase: value });
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  // Function to recalculate row numbers
  const updateRowNumbers = () => {
    const invoicesWithRowNumber = invoices.map((invoice, index) => {
      return { ...invoice, rowNumber: (pagination.current - 1) * 7 + (index + 1) };
    });
    setInvoices(invoicesWithRowNumber);
  };

  // Function to handle row deletion
  const handleRowDelete = (selectedRowIds) => {
    const updatedInvoices = invoices.filter(
      (invoice) => !selectedRowIds.includes(invoice.id)
    );
    setInvoices(updatedInvoices);
    updateRowNumbers(); // Recalculate row numbers after deletion
  };

  const columns = [
    { field: "rowNumber", headerName: `${t('SR-NO.')}`, width: 70 },
    { field: "invoiceNumber", headerName: `${t('Invoice No.')}`, width: 90 },
    { field: "companyName", headerName: `${t("Company Name")}`, width: 200 },
    { field: "vatNumber", headerName: `${t("Vat Number")}`, width: 100 },
    { field: "total", headerName: `${t("Total")}`, width: 120 },
    {
      field: "invoiceproducts",
      headerName: `${t("No. of Products")}`,
      renderCell: (text) => {
        return (
          <div>
            <Button
              variant="outlined"
              onClick={() => handleOpenInvoiceProducts(text)}
            >
              {text?.value?.length}
            </Button>
          </div>
        );
      },
      width: 150,
    },
    { field: "po", headerName: "P.O", width: 100 ,
    renderCell: (text) => (
      <span>{text?.value || "N/A"}</span>
    )
    },
    { field: "payment", headerName: `${t("Payment")}`, width: 100,
    renderCell: (text) => (
      <span>{text?.value || "N/A"}</span>
    )
    }, 
    {
      field: "invoiceDate",
      headerName: `${t("Invoice Date")}`,
      renderCell: (text) => (
        <span>{moment.utc(text?.value).format("YYYY-MM-DD")}</span>
      ),
      width: 130,
    },
  ];

  let actionColumn = [
    {
      headerName: `${t("Actions")}`,
      renderCell: (record) => {
        
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            {(AppContext?.userInfo.role === "admin" || AppContext?.userInfo.role === "user") &&(
                <PdfIcon
                  sx={{ cursor: "pointer", color: "#FF0000" }}
                  onClick={() => printInvoice(record.row)}
                  />
            )} 
             {(AppContext?.userInfo.role === "admin" || AppContext?.userInfo.role === "sub-admin" &&
             AppContext?.userInfo.canDelete === true ) && (
              
                  <EditIcon
                  onClick={() => UpdateClientModal(record.row)}
                  // console.log(record.row)
                  sx={{ cursor: "pointer", color: "#41c1ba" }}
                /> 
              
              )}
            
            {(AppContext?.userInfo.role === "admin" || AppContext?.userInfo.role === "sub-admin" &&
             AppContext?.userInfo.canDelete === true) && (
            <DeleteIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenDelModal(record.row)}
            />
            )}
            {(AppContext?.userInfo.role === "user") && (
            <KeyboardReturnIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => OpenSalesModal(record?.row)} 
            />
            )}
            {(AppContext?.userInfo.role === "user") && (
            <ContentCopyIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => duplicateInvoice(record?.row)} 
            />
            )}
          </Box>
        );
      },
    },
  ];

  let userColumn = [
    { 
      field: "user", headerName: "User Name",
      renderCell: (text) => {
        return (
          <div> {text.value?.fullName}</div>
        );
      },
      width: 70
    },
  ]

  const handleSalesReturn = (id) => {
    let tempData = { salesReturn: true };
    Request.put(`/updateSalesReturn/${id}`, tempData)
      .then((result) => {
        CloseSalesModal();
        showToast("success", "Invoice has been moved to sales returned");
        getData({ pagination, searchPhrase });
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err?.response?.data?.error);
        }
      });
  };
  
  function printInvoice(invoice) {
    // if(AppContext?.userInfo.invoiceType != "normal" && AppContext?.userInfo.invoiceType != "simple"){
  //   window.open(`/invoice_custom/${invoice.id}`, "_blank")?.focus();
  // }else{
    window.open(`/invoice/${invoice.id}`, "_blank")?.focus();
  // }
}
  
  function UpdateClientModal(invoice) { 
      navigate(`/updateInvoice/${invoice.id}`);
    {AppContext?.userInfo.role === "user" ? showToast("info", "Edit Description Only.") : showToast("info", "Edit Invoice No.Only.");}
    
  }

  function duplicateInvoice(invoice) { 
    setOpenDuplicateModal(true)
    setDupInvoice(invoice)
  }
  
  function navigateDuplicateInvoice(invoice){
    navigate(`/duplicateInvoice/${invoice.id}`);
  } 

  function deleteInvoice(invoice) {
    Request.delete(`/delInvoice/${invoice["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setUTCHours(0, 0, 0, 0);
    return normalized;
  };
  const showInvoiceDate = AppContext?.userInfo?.showInvoice ? new Date(AppContext?.userInfo?.showInvoice) : null;
  const createInvoice = AppContext?.userInfo?.createInvoice ? new Date(AppContext?.userInfo?.createInvoice) : null;
  const currentDate = normalizeDate(new Date());  
  console.log("first", showInvoiceDate, "second", currentDate);
  
  return (
    <Box>

      {(AppContext?.userInfo.role === "user" && createInvoice === null || createInvoice >= currentDate) && (
        <Button
          className="smallScreen-btn"
          onClick={() => navigate(`/addInvoice`)}
          variant="contained"
          sx={{
            fontSize: "30px",
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            height: "40px",
            float: "right",
            marginRight: "12px",
            textAlign: "center",
          }}
        >
          +
        </Button>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "0px",
        }}
      >
        <Box sx={{display:"flex"}}>
        <Box
          onMouseOver={() => setShowDatePicker(true)}
          onMouseOut={() => setShowDatePicker(false)}
          sx={{
            width: "332px",
            position: "relative",
            "& .rdrDateRangePickerWrapper": {
              width: "100%",
              "& .rdrCalendarWrapper ": {
                width: "100%",
              },
            },
            "& .rdrMonthAndYearWrapper": {
              position: "absolute",
              top: 70,
              background: "white",
              width: "100%",
              display: showDatePicker ? "" : "none",
              zIndex: 999,
              boxShadow: "0px 3px 8px rgb(100,100,100)",
            },
            "& .rdrMonths ": {
              position: "absolute",
              top: 130,
              background: "white",
              display: showDatePicker ? "" : "none",
              zIndex: 999,
              boxShadow: "0px 3px 8px rgb(100,100,100)",
            },
          }}
        >
          <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            type="text"
            autoComplete="off"
            placeholder= {t("Search Company/Invoice NO.")}
            onChange={(event) => searchChange(event.target.value)}
            sx={{
              width: "250px",
              marginRight: "20px",
              "& .MuiOutlinedInput-input": { height: "1em" },
              "& fieldset": {
                borderColor: "#365b6d !important",
              },
            }}
          />
        </Box>
        </Box>
        {(AppContext?.userInfo.role === "user" && createInvoice === null || createInvoice >= currentDate) && (

        
          <Box sx={{ display: "flex", alignItems: "center", gap:"10px" }}>
            <Button
              className="largeScreen-btn"
              onClick={() => navigate(`/addInvoice`)}
              variant="contained"
              sx={{
                background: "#365b6d",
                "&:hover": { backgroundColor: "#365b6d" },
                height: "40px",
              }}
            >
            {t("ADD NEW INVOICE")}
            </Button>            
          </Box>
        )}
      </Box>

      <div style={{ height: "calc(100vh - 235px)", width: "100%" }}>
        <Typography variant="h6" sx={{ color: "#365b6d", textAlign: "center" }}>
          {t("All Invoices")}
        </Typography>
        <>
    {showInvoiceDate === null || showInvoiceDate >= currentDate ? (
      loading ? (
        <Loader />
      ) : (
        <>
          <DataGrid
            // rows={invoices}
            rows={invoices.map((invoice, index) => ({
              ...invoice,
              rowNumber: (pagination.current - 1) * 7 + (index + 1),
            }))}
            columns={tableColumns}
            disableColumnMenu
            hideFooter
            checkboxSelection={false}
            disableSelectionOnClick
            sx={{ background: "white" }}
            onSelectionModelChange={(newSelection) =>
              handleRowDelete(newSelection)
            }
          />
          <Stack spacing={2} marginTop="5px">
            <Pagination
              count={Math.ceil(pagination?.total / 7)}
              page={pagination?.current}
              onChange={handleChange}
            />
          </Stack>
        </>
      )
    ) : (
      <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
        <p style={{color:"rgb(61 145 255)"}}>{t("Unfortunately, this service cannot be accessed. Contact the support person responsible for your account")}</p></div>
    )}
  </>
      </div>
      <DialogProducts
        handleCloseInvoiceProducts={handleCloseInvoiceProducts}
        openInvoiceProducts={openInvoiceProducts}
        text={selectedItem}
      />
      {openDelModal && (
        <DeleteModal
          del={deleteInvoice}
          currentUser={delInvoice}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
      {openSalesConfirm && (
        <SalesReturnConfirm
          moveToReturn={handleSalesReturn}
          currentInvoice={returnSale}
          openSalesModal={openSalesConfirm}
          setOpenSalesModal={setOpenSalesConfirm}
        />
      )}
      {openDuplicateModal && (
        <DuplicateModal
          currentUser={dupInvoice}
          openDuplicateModal={openDuplicateModal}
          setDuplicateModal={setOpenDuplicateModal}
          duplicateUser={navigateDuplicateInvoice}
        />
      )}
    </Box>
  );
}

export default AllInvoices;
