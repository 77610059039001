import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider } from "@mui/material";

function DuplicateModal({ openDuplicateModal,duplicateUser, currentUser, setDuplicateModal }) {
    
    console.log(currentUser);
    
  return (
    <Box>
      <Dialog
        open={openDuplicateModal}
        //   onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:"16px"}}>Are you sure you want to duplicate?</DialogTitle>
        <Divider />
        <DialogActions>
          <Button onClick={() => setDuplicateModal(false)}>Cancel</Button>
          <Button onClick={() => duplicateUser(currentUser) }>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DuplicateModal;
