import React, { useEffect } from "react";
import InvoiceHtml from "../Pages/InvoiceHtml";
import SimpleInvoice from "../Pages/simpleInvoice";
// import Print from "./Print";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import { Box, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { showToast } from "../Utils";
import Request from "../Utils/Request/Request";
import InvoiceTemplate from "../Pages/InvoiceTemplate";

function PrintComponent() {
  let componentRef = useRef();
  const { id } = useParams();
  const AppContext = useContext(AppStorage);
  const [screen, setScreen] = useState("50%");
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    // Function to update screen state based on screen width
    const updateScreen = () => {
      if (window.innerWidth < 600) {
        setScreen("97%");
        setIsBoxVisible(false);
      } else {
        setScreen("50%");
        setIsBoxVisible(true);
      }
    };

    // Call the updateScreen function initially
    updateScreen();

    // Attach a resize event listener
    window.addEventListener("resize", updateScreen);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreen);
    };
  }, []);
  function getPrintInvoice() {
    Request.get(`/getPrintInvoice/${id}`)
      .then((result) => {
        setInvoiceData(result.data.invoice);
        console.log(result.data.invoice);
        setUserData(result.data.UserData);
        console.log(result.data.UserData);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getPrintInvoice();
    }
  }, [AppContext?.userInfo, AppContext?.userAuthToken]);
  console.log("ddddd", AppContext?.userInfo.invoiceType);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {AppContext?.userInfo.invoiceType === "simple" &&
          invoiceData?.OfferPrice === false && (
            <Box style={{ display: isBoxVisible ? "block" : "none" }}>
              <Select
                size="small"
                sx={{
                  width: "200px",
                  "& .MuiInputLabel-shrink": {
                    color: "#41c1ba !important",
                  },
                  "& fieldset": {
                    borderColor: "#41c1ba !important",
                    "& legend": {
                      "& span": {
                        color: "#41c1ba !important",
                      },
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#41c1ba",
                  },
                }}
                onChange={(e) => {
                  setScreen(e.target.value);
                }}
                value={screen}
              >
                <MenuItem value={"50%"}>Normal view</MenuItem>
                <MenuItem value={"90%"}>Full view</MenuItem>
              </Select>
            </Box>
          )}
        {/* button to trigger printing of target component */}
        <Box>
          <Button
            variant="contained"
            sx={{
              background: "#365b6d",
              "&:hover": { backgroundColor: "#365b6d" },
              marginTop: "30px",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Box>
      </Box>

      <Box sx={{ marginTop: "50px" }}>
        {AppContext?.userInfo.role === "user" ? (
          invoiceData?.supplierInvoice === true ? (
            <InvoiceHtml ref={componentRef} flag={"purchases"} />
          ) : invoiceData?.OfferPrice === true  &&
           AppContext?.userInfo.offerType !== "normal"  ? (
            <InvoiceTemplate ref={componentRef} flag={true} type={AppContext?.userInfo.offerType}/>
          )  : invoiceData?.OfferPrice === true &&
            (AppContext?.userInfo.offerType === "normal" || AppContext?.userInfo.offerType === "") ? (
            <InvoiceHtml ref={componentRef} flag={true} sales={false}/>
          ) : AppContext?.userInfo.invoiceType === "normal" &&
            invoiceData?.OfferPrice === false &&
            invoiceData?.salesReturn === false ? (
            <InvoiceHtml ref={componentRef} flag={false} sales={false} />
          ) : invoiceData?.OfferPrice === false &&
            invoiceData?.salesReturn === true ? (
            <InvoiceHtml ref={componentRef} flag={false} sales={true} />
          ) : AppContext?.userInfo.invoiceType !== "simple" &&
            AppContext?.userInfo.invoiceType !== "normal" ? (
            <InvoiceTemplate
              ref={componentRef}
              type={AppContext?.userInfo.invoiceType}
              flag={false}
            />
          ) : (
            <SimpleInvoice ref={componentRef} screen={screen} />
          )
        ) : AppContext?.userInfo.role === "admin" &&
          userData?.invoiceType === "normal" ? (
          <InvoiceHtml ref={componentRef} flag={false} sales={false} />
        ) : AppContext?.userInfo.role === "admin" &&
          userData?.invoiceType != "simple" &&
          userData?.invoiceType != "normal" ? (
          <InvoiceTemplate flag={false} ref={componentRef} type={userData?.invoiceType} />
        ) : (
          <SimpleInvoice ref={componentRef} screen={screen} />
        )}
      </Box>
    </>
  );
}

export default PrintComponent;
