import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, TextField, MenuItem, Select } from '@mui/material';

const allOptions = {
  "Info Table": [
    { text: "Customer Address", value: "customerAddress" },
    { text: "Customer Name", value: "customerName" },
    { text: "Invoice Date", value: "invoiceDate" },
    { text: "Invoice No.", value: "invoiceNo" },
    { text: "Notes", value: "notes" },
    { text: "Notes 2", value: "notes2" },
    { text: "PO No.", value: "poNo" },
    { text: "Payment Terms", value: "paymentTerms" },
    { text: "Vat NO.", value: "vatNo" }
  ],
  "Product Table": [
    { text: "Description", value: "description" },
    { text: "Item Discount", value: "itemDiscount" },
    { text: "Item Total After Discount", value: "itemTotalAfterDiscount" },
    { text: "Item Vat", value: "itemVat" },
    { text: "Product Name", value: "productName" },
    { text: "Qty", value: "qty" },
    { text: "Sr. No", value: "srNo" },
    { text: "Item Total", value: "itemTotal" },
    { text: "Item Total After Vat", value: "itemTotalAfterVat" },
    { text: "Unit", value: "unit" },
    { text: "Unit Price", value: "unitPrice" }
  ],
  "Total Table": [
    { text: "Discount", value: "discount" },
    { text: "Extra Values", value: "extraValues" },
    { text: "Net Amount", value: "netAmount" },
    { text: "Total Before Vat", value: "totalBeforeVat" },
    { text: "Total After Discount", value: "totalAfterDiscount" },
    { text: "Vat%", value: "vat" }
  ]
};

const TemplateTable = ({ data, onChange }) => {
  const [selectedTableType, setSelectedTableType] = useState(data?.tableType || '');
  const [fields, setFields] = useState(data?.value || []);

  useEffect(() => {
    if (selectedTableType !== data.tableType) {
      setFields([]);
      setSelectedTableType(data.tableType);
    }
  }, [data.tableType]);

  const handleTableTypeChange = (event) => {
    const newTableType = event.target.value;
    setSelectedTableType(newTableType);
    setFields([]);
    onChange({ tableType: newTableType, value: [] });
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index] = { ...newFields[index], [field]: value };
    setFields(newFields);
    onChange({ tableType: selectedTableType, value: newFields });
  };

  const addField = () => {
    setFields([...fields, { label: '', value: '' }]);
  };

  const removeField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    onChange({ tableType: selectedTableType, value: newFields });
  };

  const options = allOptions[selectedTableType] || [];
  const [boxWidth, setBoxWidth] = useState(0);
  const boxRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setBoxWidth(boxRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);
    if (boxRef.current) {
      resizeObserver.observe(boxRef.current);
    }

    // Clean up observer on unmount
    return () => {
      if (boxRef.current) {
        resizeObserver.unobserve(boxRef.current);
      }
    };
  }, []);
  return (
    <Box ref={boxRef} sx={{ width: '100%' }}>
      <Typography variant="body1">Table Type:</Typography>
      <Select
        value={selectedTableType}
        onChange={handleTableTypeChange}
        displayEmpty
      >
        <MenuItem value="" disabled>Select Table Type</MenuItem>
        {Object.keys(allOptions).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>

      {selectedTableType && (
        <>
          
          {fields.map((field, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2, flexDirection: boxWidth < 400 ? 'column' : 'row'  }}>
              <TextField
                label="Custom Text"
                value={field.label}
                onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                fullWidth
              />
              <Select
                value={field.value}
                onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>Select Option</MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
              <Button sx={{color:"red",fontSize:"20px"}} onClick={() => removeField(index)}>
                X
              </Button>
            </Box>
          ))}
          <Button variant="outlined" onClick={addField} sx={{ mt: 2, display:"block" }}>
            Add Field
          </Button>
        </>
      )}
    </Box>
  );
};

export default TemplateTable;

