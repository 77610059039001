import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SideBarRole({ navItems, open }) {
  return (
    <>
      {navItems.map((link, i) => (
        <ListItem key={i} disablePadding sx={{ display: "block" }}>
          <Link to={link.path} key={link.title}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 4 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon className="sidebar-icon" icon={link.icon} />
              </ListItemIcon>
              <ListItemText
                primary={link.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </>
  );
}

export default SideBarRole;
