import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Request from "../Utils/Request/Request";
import DeleteModal from "./DeleteModal";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DuplicateModal from "../modals/DuplicateModal";

const TemplateList = () => {
  const [templates, setTemplates] = useState([]);
  const AppContext = useContext(AppStorage);

  const navigate = useNavigate();

  const [delTemp, setDelTemp] = useState("");
  const [openDelModal, setOpenDelModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [dupTemp, setDupTemp] = useState("");

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelTemp(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };
  const fetchTemplates = async () => {
    try {
      const response = await Request.get("/getAllTemplates");
      setTemplates(response.data);
    } catch (error) {
      console.error("Error fetching templates: ", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [AppContext?.userAuthToken]);

  const handleTemplateClick = (id) => {
    navigate(`/template-design/${id}`);
  };

  const handleAddTemplate = () => {
    navigate("/template-design");
  };

  const handleDeleteTemplate = async (temp) => {
    try {
      await Request.delete(`/deleteTemplate/${temp["id"]}`);
      fetchTemplates();
      showToast("success", "Deleted Successfully.");
      CloseDelModal();
    } catch (error) {
      console.error("Error deleting template: ", error);
    }
  };
  function duplicateTemplate(temp) {
    setOpenDuplicateModal(true);
    setDupTemp(temp);
  }

  function navigateDuplicateTemplate(temp) {
    navigate(`/template-duplicate/${temp.id}`);
  }
  return (
    <Box sx={{ px: 5 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddTemplate}
        sx={{ mb: 2 }}
      >
        Add New Template
      </Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((template) => (
              <TableRow key={template.id}>
                <TableCell>{template.name}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleTemplateClick(template.id)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => duplicateTemplate(template)}
                    aria-label="edit"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => OpenDelModal(template)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openDelModal && (
        <DeleteModal
          del={handleDeleteTemplate}
          currentUser={delTemp}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
      {openDuplicateModal && (
        <DuplicateModal
          currentUser={dupTemp}
          openDuplicateModal={openDuplicateModal}
          setDuplicateModal={setOpenDuplicateModal}
          duplicateUser={navigateDuplicateTemplate}
        />
      )}
    </Box>
  );
};

export default TemplateList;
