import React, { useState, useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Divider } from "@mui/material";
import Request from "../Utils/Request/Request";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../Utils/index";
import { AppStorage } from "../Utils/Context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const AddedElement = ({
  index,
  setFormData,
  formData,
  product,
  singleProduct,
  validate,
  getInvoice,
  duplicate
}) => {
  const { id } = useParams();
  const AppContext = useContext(AppStorage);
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Grid container spacing={2} sx={{ padding: "3px 0px" }}>
        <Grid item xs={6} sm={6} md={4}>
          <InputLabel id="demo-simple-select-label">{t("Products")}</InputLabel>
          <Autocomplete
            size="small"
            options={product}
            getOptionLabel={(option) => option.name}
            value={product.find((item) => item.id === singleProduct.productId) || null}
            onChange={(event, newValue) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].productId = newValue ? newValue.id : "";
              dupObj.invoiceproducts[index].productName = newValue ? newValue.name : "";
              setFormData(dupObj);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  "& .MuiInputLabel-shrink": { color: "#41c1ba !important" },
                  "& fieldset": { borderColor: "#41c1ba !important" },
                }}
              />
            )}
          />

          {!validate &&
            (formData.invoiceproducts[index].productName === null ||
              formData.invoiceproducts[index].productName === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Product is required
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <InputLabel id="demo-simple-select-label">
            {t("Description")}
          </InputLabel>
          <TextField
            size="small"
            // inputProps={{
            //   readOnly: getInvoice !== null
            //       ? true
            //       : false,
            // }}
            value={singleProduct?.description}
            onChange={(e) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].description = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "100%",
            }}
          />
          {!validate &&
            (formData.invoiceproducts[index].description === null ||
              formData.invoiceproducts[index].description === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Add description
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <InputLabel id="demo-simple-select-label">{t("Unit")}</InputLabel>
          <TextField
            size="small"
            // inputProps={{ readOnly: getInvoice != null ? true : false }}
            value={singleProduct?.unit}
            onChange={(e) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].unit = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "100%",
            }}
          />
          {!validate &&
            (formData.invoiceproducts[index].unit === null ||
              formData.invoiceproducts[index].unit === "") && (
              <div style={{ color: "red", fontSize: "small" }}>Add Unit</div>
            )}
        </Grid>
        <Grid item xs={6} sm={4} md={1}>
          <InputLabel id="demo-simple-select-label">{t("qty")}</InputLabel>
          <TextField
            size="small"
            // inputProps={{ readOnly: id === undefined ? false : true }}
            type={"number"}
            value={singleProduct?.qty}
            onChange={(e) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].qty = e.target.value;
              dupObj.invoiceproducts[index].vat =
                (AppContext?.userInfo?.vat / 100) *
                (singleProduct?.unitPrice * singleProduct?.qty -
                  +singleProduct?.discount);
              dupObj.invoiceproducts[index].total = (
                singleProduct?.unitPrice * singleProduct?.qty
              ).toFixed(2);
              dupObj.invoiceproducts[index].totalAfterDiscount = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount
              ).toFixed(2);
              dupObj.invoiceproducts[index].totalAfterVat = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount +
                +singleProduct?.vat
              ).toFixed(2);
              setFormData(dupObj);
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
            }}
          />
          {!validate &&
            (formData.invoiceproducts[index].qty === null ||
              formData.invoiceproducts[index].qty === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Add Quantity
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <InputLabel id="demo-simple-select-label">
            {t("Unit Price")}
          </InputLabel>
          <TextField
            type={"number"}
            // inputProps={{ readOnly: id === undefined ? false : true }}
            size="small"
            value={singleProduct?.unitPrice}
            onChange={(e) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].unitPrice = e.target.value;
              dupObj.invoiceproducts[index].vat =
                (AppContext?.userInfo?.vat / 100) *
                (singleProduct?.unitPrice * singleProduct?.qty -
                  +singleProduct?.discount);
              dupObj.invoiceproducts[index].total = (
                singleProduct?.unitPrice * singleProduct?.qty
              ).toFixed(2);
              dupObj.invoiceproducts[index].totalAfterDiscount = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount
              ).toFixed(2);
              dupObj.invoiceproducts[index].totalAfterVat = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount +
                +singleProduct?.vat
              ).toFixed(2);
              setFormData(dupObj);
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
            }}
          />
          {!validate &&
            (formData.invoiceproducts[index].unitPrice === null ||
              formData.invoiceproducts[index].unitPrice === "") && (
              <div style={{ color: "red", fontSize: "small" }}>Enter Price</div>
            )}
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <InputLabel id="demo-simple-select-label">{t("Total")}</InputLabel>
          <TextField
            size="small"
            inputProps={{ readOnly: true }}
            type={"number"}
            value={singleProduct?.total}
            sx={{
              "& input": {
                color: "grey !important",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0A0A0 !important",
              },
              "& fieldset": {
                borderColor: "#A0A0A0 !important",
                "& legend": {
                  "& span": {
                    color: "#A0A0A0 !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A0A0A0",
              },
              width: "100%",
            }}
          />
          {!validate &&
            (formData.invoiceproducts[index].total === null ||
              formData.invoiceproducts[index].total === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                total is required
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <InputLabel id="demo-simple-select-label">{t("Discount")}</InputLabel>
          <TextField
            size="small"
            type={"number"}
            // inputProps={{ readOnly: getInvoice != null ? true : false }}
            value={singleProduct?.discount ? singleProduct?.discount : 0}
            onChange={(e) => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts[index].discount = e.target.value;
              dupObj.invoiceproducts[index].vat =
                (AppContext?.userInfo?.vat / 100) *
                (singleProduct?.unitPrice * singleProduct?.qty -
                  +singleProduct?.discount);
              dupObj.invoiceproducts[index].totalAfterDiscount = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount
              ).toFixed(2);
              dupObj.invoiceproducts[index].totalAfterVat = (
                singleProduct?.unitPrice * singleProduct?.qty -
                +singleProduct?.discount +
                +singleProduct?.vat
              ).toFixed(2);
              setFormData(dupObj);
            }}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "100%",
            }}
          />
          {/* {!validate &&
            (formData.invoiceproducts[index].discount === null ||
              formData.invoiceproducts[index].discount === "" ) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Add Discount
              </div>
            )} */}
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <InputLabel id="demo-simple-select-label">
            {t("Total after discount")}
          </InputLabel>
          <TextField
            size="small"
            type={"number"}
            inputProps={{ readOnly: true }}
            value={singleProduct?.totalAfterDiscount}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <InputLabel id="demo-simple-select-label">{t("Vat")} {AppContext?.userInfo?.vat}%</InputLabel>
          <TextField
            type={"number"}
            size="small"
            inputProps={{ readOnly: true }}
            value={(+singleProduct?.vat)?.toFixed(2)}
            sx={{
              "& input": {
                color: "grey !important",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0A0A0 !important",
              },
              "& fieldset": {
                borderColor: "#A0A0A0 !important",
                "& legend": {
                  "& span": {
                    color: "#A0A0A0 !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A0A0A0",
              },
            }}
          />

          {!validate &&
            (formData.invoiceproducts[index].vat === null ||
              formData.invoiceproducts[index].vat === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                vat is required
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <InputLabel id="demo-simple-select-label">
            {t("Total after vat")}
          </InputLabel>
          <TextField
            size="small"
            type={"number"}
            inputProps={{ readOnly: true }}
            value={singleProduct?.totalAfterVat}
            sx={{
              "& .MuiInputLabel-shrink": {
                color: "#41c1ba !important",
              },
              "& fieldset": {
                borderColor: "#41c1ba !important",
                "& legend": {
                  "& span": {
                    color: "#41c1ba !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#41c1ba",
              },
              width: "100%",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={1} md={1}>
          <Button
            size="small"
            // disabled={id === undefined ? false : true}
            onClick={() => {
              const dubObj = { ...formData };
              dubObj?.invoiceproducts.splice(index, 1);
              setFormData(dubObj);
            }}
            variant="contained"
            sx={{
              width: "100%",
              marginTop: "21px",
              height: "40px",
              background: "#365b6d",
              padding: "11px 1px 7px",
              "&:hover": { backgroundColor: "#365b6d" },
            }}
          >
            X
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ background: "#808b97", marginTop: "13px" }} />
    </Box>
  );
};

function AddPurchase({duplicate}) {
  const navigate = useNavigate();
  const AppContext = useContext(AppStorage);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const initialState = {
    supplierName: "",
    vatNumber: "",
    po: "",
    invoiceDate: dayjs(new Date()),
    payment: "",
    address: "",
    vat: "",
    supplierNumber: null,
    id: null,
    discount: 0,
    totalAfterDiscount: null,
    totalAfterVat: null,
    invoiceproducts: [
      {
        productId: null,
        productName: "",
        description: "",
        qty: null,
        unitPrice: null,
        vat: "",
        total: null,
        unit: "",
        discount: 0,
      },
    ],
    extraValues: [
      {
        text: "",
        value: null,
      },
    ],
  };
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(true);

  const validateFormData = () => {
    if (
      //  formData.offerNumber === "" || formData.offerNumber === null ||
      //  formData.invoiceNumber === "" || formData.invoiceNumber === null ||
      formData.vatNumber === "" ||
      formData.payment === "" ||
      formData.po === "" ||
      formData.invoiceDate === "" ||
      formData.invoiceDate === null ||
      formData.invoiceproducts.length < 1 ||
      formData.invoiceproducts.some(
        (data) =>
          data.productId === "" ||
          data.productName === "" ||
          data.description === "" ||
          data.unitPrice === null ||
          data.vat === "" ||
          data.total === null ||
          data.qty === "" ||
          data.qty === null ||
          data.qty < 1 ||
          data.unit === "" ||
          data.discount === null ||
          data.totalAfterDiscount === null ||
          data.totalAfterVat === null
      )
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    
    // console.log("first click")
    if (validateFormData()) {
      setIsSubmitting(true);
      let tempData = {
        id: formData.id,
        supplierNumber: formData.supplierNumber,
        invoiceNumber: null,
        offerNumber: null,
        supplierName: formData.supplierName,
        vatNumber: formData.vatNumber,
        po: formData.po,
        vat: formData.invoiceproducts
        ?.map((item) => {
          const vat = +item?.vat;
          return vat;
        })
        .reduce((accumulator, value) => {
          return +accumulator + +value;
        }, 0)
        .toFixed(2),
        invoiceDate: new Date(formData.invoiceDate).setUTCHours(0,0,0,0),
        payment: formData.payment,
        address: formData.address,
        supplierInvoice: true,
        discount: formData.invoiceproducts
          .map((item) => {
            const totalDiscount = parseFloat(item?.discount);
            return totalDiscount;
          })
          .reduce((accumulator, value) => {
            return +accumulator + +value;
          }, 0)
          ?.toFixed(2),

        total: formData.invoiceproducts
          .map((item) => {
            const total = parseFloat(item?.unitPrice) * +item.qty;
            return total.toFixed(2);
          })
          .reduce((accumulator, value) => {
            return +accumulator + +value;
          }, 0)
          ?.toFixed(2),

        totalAfterDiscount: formData.invoiceproducts
          .map((item) => {
            const total =
              parseFloat(item?.unitPrice) * +item.qty - +item.discount;
            return total;
          })
          .reduce((accumulator, value) => {
            return +accumulator + +value;
          }, 0)
          ?.toFixed(2),

        totalAfterVat: formData.invoiceproducts
          .map((item) => {
            const total =
              parseFloat(item?.unitPrice) * +item.qty -
              +item.discount +
              +item.vat;
            return total;
          })
          .reduce((accumulator, value) => {
            return +accumulator + +value;
          }, 0)
          ?.toFixed(2),

        invoiceProducts: formData.invoiceproducts,
        extraValues: formData.extraValues,
      };
      if (getInvoice === null || duplicate === true) {
        // setIsSubmitting(true);
        Request.post("/createInvoice", tempData)
          .then((result) => {
            setIsSubmitting(false);
            toast.success("New Invoice Added") 
            setTimeout(() => {
             navigate("/allSupplierInvoice")
            }, 1500);
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else if (err.response.status == 409) {
              showToast("error", err.response.data.error);
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      } else {
        tempData["id"] = getInvoice["id"];
        tempData["userId"] = getInvoice["userId"];
        // setIsSubmitting(true);
        Request.put("/updateInvoice", tempData)
          .then((result) => {
            console.log("Data: ", result);
          toast.success("Invoice Updated")
            setTimeout(() => {
              navigate("/allSupplierInvoice")
              setIsSubmitting(false);
            }, 1500);
          })
          .catch((err) => {
            setIsSubmitting(false);
            console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else if (err.response.status == 409) {
              showToast("error", err.response.data.error);
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      }
    }
  };

  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [getInvoice, setGetInvoice] = useState(null);
  //   const [clientCompany, setClientCompany] = useState("");
  function getProducts() {
    Request.get("/getAllProducts")
      .then((result) => {
        setProducts(result.data["data"]);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function getSuppliers() {
    Request.get("/getAllSuppliers")
      .then((result) => {
        setSuppliers(result.data["data"]);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

 

  function getInvoices() {
    Request.get(`/getUpdateInvoice/${id}`)
      .then((result) => {
        console.log(result);
        setGetInvoice(result.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const [supplierNum, setSupplierNum] = useState();
  
  function getSupplierNumber() {
    Request.get("/getSupplierNumber")
      .then((result) => {
        console.log(result.data.data);
        setSupplierNum(result.data.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    getSupplierNumber();
  }, [AppContext?.userInfo]);

  useEffect(() => {
    if (getInvoice == null || duplicate === true) {
      let dupObj = { ...formData };
      dupObj.supplierNumber =  supplierNum + 1;
      setFormData(dupObj);
    }
  }, [getInvoice, supplierNum]);


  useEffect(() => {
    if (getInvoice !== null) {
      let dupObj = { ...formData };
      dupObj.id = duplicate === true ? null : getInvoice?.id;
      dupObj.supplierNumber = duplicate === true ? supplierNum + 1 : getInvoice?.supplierNumber;
      dupObj.supplierName = getInvoice?.supplierName;
      dupObj.vatNumber = getInvoice?.vatNumber;
      dupObj.po = getInvoice?.po;
      dupObj.vat = getInvoice?.vat;
      dupObj.payment = getInvoice?.payment;
      dupObj.invoiceDate = duplicate === true ? dayjs(new Date()) : dayjs(getInvoice?.invoiceDate);
      dupObj.invoiceproducts = !!getInvoice?.invoiceproducts?.length
        ? getInvoice?.invoiceproducts?.map((item) => {
            return {
              productId: item?.productId,
              productName: item?.productName,
              description: item?.description,
              qty: item?.qty,
              unitPrice: item?.unitPrice,
              vat: item?.vat,
              total: item?.total,
              unit: item?.unit,
              discount: item?.discount,
              totalAfterDiscount: item?.totalAfterDiscount,
              totalAfterVat: item?.totalAfterVat,
            };
          })
        : [
            {
              productId: null,
              productName: "",
              description: "",
              qty: null,
              unitPrice: null,
              vat: "",
              total: null,
              unit: "",
              discount: null,
              totalAfterDiscount: null,
              totalAfterVat: null,
            },
          ];

          dupObj.extraValues = !!getInvoice?.extravalues?.length
          ? getInvoice?.extravalues?.map((item) => {
              return {
                text: item?.text,
                value: item?.value,
              };
            })
          : [
              {
                text: "",
                value: null,
              },
            ];
  
            setFormData(dupObj);
           
          }
  }, [getInvoice]);


  const handleSupplierNumberChange = (e) => {
    let dupObj = { ...formData };
    dupObj.supplierNumber = e.target.value;
    setFormData(dupObj);
  };

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getProducts();
      getSuppliers();
      getInvoices();
    }
  }, [AppContext?.userAuthToken, AppContext?.userInfo]);

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3} md={3}>
          <InputLabel id="demo-simple-select-label">
          {t("Supplier Invoice No.")}
          </InputLabel>
          <TextField
            type={"number"}
            size="small"
            inputProps={{
              readOnly:
                AppContext?.userInfo.role === "admin" ||
                (AppContext?.userInfo.role === "sub-admin" ||
                AppContext?.userInfo.role === "user" && AppContext?.userInfo.invoiceNumberEdit === true &&
                  getInvoice === null)
                  ? false
                  : true,
            }}
            value={formData.supplierNumber}
            sx={{
              "& input": {
                color:
                  AppContext?.userInfo.role === "user" &&
                  "rgb(105,105,105) !important",
              },
              "& .MuiInputLabel-shrink": {
                color:
                  AppContext?.userInfo.role === "user"
                    ? "#A0A0A0 !important"
                    : "#41c1ba ",
              },
              width: "80%",
              "& fieldset": {
                borderColor:
                  AppContext?.userInfo.role === "user"
                    ? "#A0A0A0 !important"
                    : "#41c1ba ",
                "& legend": {
                  "& span": {
                    color:
                      AppContext?.userInfo.role === "user"
                        ? "#A0A0A0 !important"
                        : "#41c1ba ",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor:
                  AppContext?.userInfo.role === "user"
                    ? "#A0A0A0 !important"
                    : "#41c1ba",
              },
            }}
            onChange={handleSupplierNumberChange}
          />
            {
            (formData.offerNumber === null || formData.offerNumber === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Supplier Number required
              </div>
            )}
        </Grid>
        <Grid item xs={6} sm={5} md={3}>
          <Box>
            <InputLabel id="demo-simple-select-label">
              {t("Purchase Date")}
            </InputLabel>
            <LocalizationProvider
            dateAdapter={AdapterDayjs}>
              <DemoContainer
                sx={{
                  padding: "unset !important",
                  height: "2.7em !important",
                }}
                components={["DatePicker"]}
              >
                <DatePicker
                  value={formData.invoiceDate}
                  
                  sx={{
                    // height: "1.5375em !important",
                    "& input": {
                      color: AppContext?.userInfo.dateEdit === false && "rgb(105,105,105) !important",
                      // height: "1.4375em !important",
                      padding: "9px !important",
                    },
                    "& .MuiInputLabel-shrink": {
                      color:  AppContext?.userInfo.dateEdit === false  ? "#A0A0A0 !important" : "#41c1ba !important",
                      // height: "1.975em !important",
                      padding: "9px !important",
                    },
                    "& fieldset": {
                      borderColor: AppContext?.userInfo.dateEdit === false ? "#A0A0A0 !important" : "#41c1ba !important",
                      "& legend": {
                        "& span": {
                          color: AppContext?.userInfo.dateEdit === false ? "#A0A0A0 !important" : "#41c1ba !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: AppContext?.userInfo.dateEdit === false ? "#A0A0A0 !important" : "#41c1ba !important",
                    },
                  }}
                  onChange={(newValue) => {
                    const dupObj = { ...formData };
                    dupObj.invoiceDate = newValue;
                    setFormData(dupObj);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          {!validated &&
            (formData.invoiceDate === null || formData.invoiceDate === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Date is required
              </div>
            )}
        </Grid>
              {/* {flag === true && */}
        <Grid item xs={6} sm={3} md={3}>
          <Box>
            <InputLabel id="demo-simple-select-label">
            {t("Supplier")} 
            </InputLabel>
            <Select
              size="small"
              // inputProps={{ readOnly: getInvoice != null ? true : false }}
              sx={{
                width: "80%",
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
              }}
              value={formData.supplierName}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.supplierName = e.target.value;
                const findClient = suppliers.find(
                  (data) => data.supplierName === e.target.value
                );
                dupObj.vatNumber = findClient.vatNumber ? findClient.vatNumber : "";
                dupObj.address = findClient.address ? findClient.address : "";
                setFormData(dupObj);
              }}
            >
              {!!suppliers.length &&
                suppliers.map((comp, i) => (
                  <MenuItem key={i} value={comp.supplierName}>
                    {comp.supplierName}
                  </MenuItem>
                ))}
            </Select>
            {!validated &&
              (formData.supplierName === null ||
                formData.supplierName === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Select Supplier
                </div>
              )}
          </Box>
        </Grid>
        {/* } */}
        <Grid item xs={6} sm={3} md={3}>
          <Box>
            <InputLabel id="demo-simple-select-label">
              {t("Vat Number")}
            </InputLabel>
            <TextField
              type={"number"}
              inputProps={{ readOnly: true }}
              value={formData.vatNumber}
              size="small"
              sx={{
                "& input": {
                  color: "rgb(105,105,105) !important",
                },
                "& .MuiInputLabel-shrink": {
                  color: "#A0A0A0 !important",
                },
                "& fieldset": {
                  borderColor: "#A0A0A0 !important",
                  "& legend": {
                    "& span": {
                      color: "#A0A0A0 !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A0A0A0",
                },
              }}
            />
          </Box>
        </Grid>
        
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              background: "#365b6d",
              justifyContent: "space-between",
              "&:hover": { backgroundColor: "#365b6d" },
            }}
            onClick={() => {
              const dupObj = { ...formData };
              dupObj.invoiceproducts.push({
                productId: null,
                productName: "",
                description: "",
                qty: null,
                unitPrice: null,
                vat: null,
                total: null,
                discount: 0,
              });
              setFormData(dupObj);
            }}
          >
            {t("Add Product")}
            <FontAwesomeIcon
              style={{ color: "white", width: "1.5em", height: "18px" }}
              icon={faPlus}
            />
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{
          height: "calc(100vh - 417px)",
          overflowY: "scroll",
          marginTop: "12px",
        }}
      >
        {!!formData?.invoiceproducts.length &&
          formData?.invoiceproducts?.map((singleProduct, i) => (
            <AddedElement
              key={i}
              index={i}
              setFormData={setFormData}
              formData={formData}
              product={products}
              singleProduct={singleProduct}
              validate={validated}
              getInvoice={getInvoice}
              duplicate={duplicate}
            />
          ))}
        {!validated && formData.invoiceproducts < 1 && (
          <div style={{ color: "red", fontSize: "small" }}>Add Product</div>
        )}
      </Box>
      <Divider />
      <Grid container spacing={2} sx={{ marginTop: "15px" }}>
        <Grid item xs={6} sm={3} md={2}>
          <Box>
            <InputLabel id="demo-simple-select-label">
              {t("Select Payment")}
            </InputLabel>
            <Select
              size="small"
              // inputProps={{ readOnly: getInvoice != null ? true : false }}
              sx={{
                width: "100%",
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
              }}
              value={formData.payment}
              onChange={(e) => {
                const dupObj = { ...formData };
                dupObj.payment = e.target.value;
                setFormData(dupObj);
              }}
            >
              <MenuItem value={t("Cash")}>{t("Cash")}</MenuItem>
              <MenuItem value={t("Credit")}>{t("Credit")}</MenuItem>
              <MenuItem value={t("Transfer")}>{t("Transfer")}</MenuItem>
            </Select>
          </Box>
          {!validated &&
            (formData.payment === null || formData.payment === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Payment Method
              </div>
            )}
        </Grid>
            
        <Grid item xs={6} sm={3} md={2}>
          <Box>
            <InputLabel id="demo-simple-select-label">{t("P.O")}</InputLabel>
            <TextField
              type={"number"}
              // inputProps={{ readOnly: getInvoice != null ? true : false }}
              size="small"
              value={formData.po}
              onChange={(e) => {
                const dupObj = { ...formData };
                dupObj.po = e.target.value;
                setFormData(dupObj);
              }}
              sx={{
                "& .MuiInputLabel-shrink": {
                  color: "#41c1ba !important",
                },
                "& fieldset": {
                  borderColor: "#41c1ba !important",
                  "& legend": {
                    "& span": {
                      color: "#41c1ba !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#41c1ba",
                },
              }}
            />
          </Box>
          {!validated && (formData.po === null || formData.po === "") && (
            <div style={{ color: "red", fontSize: "small" }}>
              Enter P.O. to proceed
            </div>
          )}
        </Grid>
          
        <Grid item xs={6} sm={3} md={2}>
          <InputLabel id="demo-simple-select-label">{t("Price")}</InputLabel>
          <TextField
            type={"number"}
            size="small"
            inputProps={{ readOnly: true }}
            value={formData.invoiceproducts
              ?.map((item) => +item?.unitPrice * +item?.qty)
              .reduce((accumulator, value) => {
                return +accumulator + +value;
              }, 0)
              .toFixed(2)}
            sx={{
              "& input": {
                color: "grey !important",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0A0A0 !important",
              },
              "& fieldset": {
                borderColor: "#A0A0A0 !important",
                "& legend": {
                  "& span": {
                    color: "#A0A0A0 !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A0A0A0",
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <InputLabel id="demo-simple-select-label">{t("Vat")} {AppContext?.userInfo?.vat}%</InputLabel>
          <TextField
            type={"number"}
            size="small"
            inputProps={{ readOnly: true }}
            value={formData.invoiceproducts
              ?.map((item) => {
                const vat = +item?.vat;
                return vat;
              })
              .reduce((accumulator, value) => {
                return +accumulator + +value;
              }, 0)
              .toFixed(2)}
            sx={{
              "& input": {
                color: "grey !important",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0A0A0 !important",
              },
              "& fieldset": {
                borderColor: "#A0A0A0 !important",
                "& legend": {
                  "& span": {
                    color: "#A0A0A0 !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A0A0A0",
              },
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={3} >
          <InputLabel id="demo-simple-select-label">
            {t("Grand Total")}
          </InputLabel>
          <TextField
            type={"number"}
            size="small"
            inputProps={{ readOnly: true }}
            value={formData.invoiceproducts
              .map((item) => {
                const total =
                  parseFloat(item?.unitPrice) * +item.qty -
                  +item.discount +
                  +item.vat;
                return total;
              })
              .reduce((accumulator, value) => {
                return +accumulator + +value;
              }, 0)
              ?.toFixed(2)}
            sx={{

              "& input": {
                color: "grey !important",
              },
              "& .MuiInputLabel-shrink": {
                color: "#A0A0A0 !important",
              },
              "& fieldset": {
                borderColor: "#A0A0A0 !important",
                "& legend": {
                  "& span": {
                    color: "#A0A0A0 !important",
                  },
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#A0A0A0",
              },
              width:"100%"
            }}
          />
        </Grid>
       
      </Grid>
      <Button
        onClick={handleSubmit}
        variant="contained"
        sx={{
          background: "#365b6d",
          "&:hover": { backgroundColor: "#365b6d" },
          marginTop: "23px",
        }}
        disabled={isSubmitting}
      >
        {t("Submit")}
      </Button>
      <Toaster
        toastOptions={{
          duration: 1500,
          style: {
            background: "#365b6d",
            color: "#fff",
          },
        }}
      />
    </Box>
  );
}

export default AddPurchase;
