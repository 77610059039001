import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  faFileInvoice,
  faUserGroup,
  faBoxesPacking,
  faUserFriends,
  faUserTie,
  faDollar,
  faRedo,
  faVanShuttle,
  faStore,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppStorage } from "../Utils/Context/AppContext";
import { useTranslation } from "react-i18next";
import SideBarRole from "./SideBarRole";

function SideBarList({ open }) {
  const [navItems, setNavItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const AppContext = useContext(AppStorage);
  const { t, i18n } = useTranslation();

  const links = [
    {
      title: `${t("Invoices")}`,
      path: "/invoices",
      access: ["admin", "user", "sub-admin"],
      icon: faFileInvoice,
    },
    {
      title: "Users",
      path: "/allUsers",
      access: ["admin", "sub-admin"],
      icon: faUserGroup,
    },
    {
      title: "Sub Admin",
      path: "/allSubAdmin",
      access: ["admin"],
      icon: faUserTie,
    },
    {
      title: `${t("Clients")}`,
      path: "/allClients",
      access: ["user"],
      icon: faUserFriends,
    },
    {
      title: `${t("Products")}`,
      path: "/allProducts",
      access: ["user"],
      icon: faBoxesPacking,
    },
    {
      title: `${t("Price Offer")}`,
      path: "/allOfferPrice",
      access: ["user"],
      icon: faDollar,
    },
    {
      title: `${t("All Sales Return")}`,
      path: "/allSalesReturn",
      access: ["user"],
      icon: faRedo,
    },
    {
      title: `${t("All Suppliers")}`,
      path: "/allSuppliers",
      access: ["user"],
      icon: faVanShuttle,
    },
    {
      title: `${t("All Purchases")}`,
      path: "/allSupplierInvoice",
      access: ["user"],
      icon: faStore,
    },
    {
      title: `${t("Design Template")}`,
      path: "/templateList",
      access: ["admin"],
      icon: faImage,
    },
  ];

  useEffect(() => {
    if (AppContext?.userInfo) {
      const userRole = AppContext.userInfo.role;
      const filteredItems = links.filter((item) =>
        item.access.includes(userRole)
      );
      setNavItems(filteredItems);
      setIsLoading(false);
    }
  }, [AppContext?.userInfo, i18n.language]);
  if (isLoading) {
    return null; 
  }
  return <SideBarRole navItems={navItems} open={open} />;
}

export default SideBarList;
