import React, { forwardRef, useEffect, useContext, useState } from "react";
import Logo from "../images/logoCrop.png";
import Grid from "@mui/material/Grid";
import { Box, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppStorage } from "../Utils/Context/AppContext";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils";
import moment from "moment";

const SimpleInvoice = forwardRef(({ screen }, ref) => {
  const { id } = useParams();
  const AppContext = useContext(AppStorage);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);

  function getPrintInvoice() {
    Request.get(`/getPrintInvoice/${id}`)
      .then((result) => {
        setInvoiceData(result.data.invoice);
        setUserData(result.data.UserData);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    if (invoiceData?.companyName && invoiceData?.invoiceNumber) {
      document.title = `${invoiceData?.companyName}_${invoiceData.invoiceNumber}`;
    }
  }, [invoiceData]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getPrintInvoice();
    }
  }, [AppContext?.userInfo, AppContext?.userAuthToken]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "5px 0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      ref={ref}
    >
      <Grid
        container
        // className="simpleHtmlInvoice"

        sx={{
          width: screen,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{ width: "100%" }}
          src={
            userData?.logo !== "" && userData?.logo !== null && userData?.logo
              ? `https://api.doryalinvoices.com/image-storage/${userData?.logo}`
                // `http://127.0.0.1:3000/image-storage/${userData?.logo}`
              : Logo
          }
          alt=""
        />

        <h4 style={{ textAlign: "center" }}>فاتورة ضريبية مبسطة</h4>
        <h5 style={{ textAlign: "center" }}>Simplified Tax Invoice</h5>
        <Box
          sx={{ display: "flex", justifyContent: "space-evenly", gap: "12px" }}
        >
          <Box>
            <h4 style={{ margin: '0.5rem 0' }}>Number Order</h4>
            <h4 style={{ margin: '0.5rem 0' }}>Company Name</h4>
            <h4 style={{ margin: '0.5rem 0' }}>Address</h4>
            <h4 style={{ margin: '0.5rem 0' }}>Vat No.</h4>
            <h4 style={{ margin: '0.5rem 0' }}>Pay Type</h4>
            <h4 style={{ margin: '0.5rem 0' }}>Invoice Time</h4>
            <h4>Invoice Date</h4>
          </Box>

          <Box>
            <h4 style={{ margin: '0.5rem 0' }}>{invoiceData?.invoiceNumber}</h4>
            <h4 style={{ margin: '0.5rem 0' }}>{invoiceData?.companyName}</h4>
            <h4 style={{ margin: '0.5rem 0' }}>{invoiceData?.address ? invoiceData?.address : "-"}</h4>
            <h4 style={{ margin: '0.5rem 0' }}>{invoiceData?.vatNumber ? invoiceData?.vatNumber : "-"}</h4>
            <h4 style={{ margin: '0.5rem 0' }}>{invoiceData?.payment}</h4>
            <h4 style={{ margin: '0.5rem 0' }}>{moment(invoiceData?.createdAt).format("h-mm-ss a")}</h4>
            <h4>{moment(invoiceData?.invoiceDate).format("YYYY-MM-DD")}</h4>
          </Box>
          <Box>
            <h4 style={{ margin: '0.5rem 0' }}>رقم الفاتورة</h4>
            <h4 style={{ margin: '0.5rem 0' }}>اسم الشركة</h4>
            <h4 style={{ margin: '0.5rem 0' }}>عنوان</h4>
            <h4 style={{ margin: '0.5rem 0' }}>ظريبه الشراء</h4>
            <h4 style={{ margin: '0.5rem 0' }}>نوع الدفع</h4>
            <h4 style={{ margin: '0.5rem 0' }}>وقت الفاتورة</h4>
            <h4>تاريخ الفاتورة</h4>
          </Box>
        </Box>
        <Divider
          sx={{ background: "#808b97", width: "100%", marginTop: "5px" }}
        />

        <h4 style={{ textAlign: "center" }}>Take Out</h4>

        <table class="tableSimple" style={{pageBreakBefore:'auto',}}>
          <tr class="simpleheader">
            <th>Total+Vat/ضريبة شامل</th> 
            <th>Total/المجموع</th><th>Qty/الكمية</th><th>Vat/ضريبة</th><th>Price/سعر الوحدة</th><th>Product/منتج</th><th>No./رقم الصنف.</th>
          </tr>
          {!!invoiceData?.invoiceproducts?.length &&
            invoiceData?.invoiceproducts?.map((item, i) => (
              <tr key={i}>
                <td>{item?.totalAfterVat}</td>
                <td>{item?.total}</td>
                <td>{item?.qty}</td>
                <td>{item?.vat ?  Number(item.vat).toFixed(2) : ''}</td>
                <td>{item?.unitPrice}</td>
                <td>{item?.productName}</td>
                <td>{i + 1}</td>
              </tr>
            ))}
        </table>
        <Divider
          sx={{ background: "#808b97", width: "100%", marginTop: "5px" }}
        />
        <Box
          sx={{ display: "flex", justifyContent: "space-between"}}
        >
          <Box sx={{mt: 2}}>
            <h3 style={{ margin: '0', marginBottom:'3.5rem' }}>{invoiceData?.total}</h3>
            <h3 style={{ margin: '0', marginBottom:'3.5rem' }}>
              {invoiceData?.vat != null ? invoiceData?.vat : invoiceData?.invoiceproducts
                ?.map((item) => {
                  const vat = +item?.vat;
                  return vat;
                })
                .reduce((accumulator, value) => {
                  return +accumulator + +value;
                }, 0)
                }
            </h3>
            <h3 style={{ margin: '0', marginBottom:'3.5rem' }}>{invoiceData?.totalAfterVat}</h3>
          </Box>

          <Box sx={{mt: 2}}>
            <div style={{ marginBottom: '1rem' }}>          
            <h3 style={{ margin: '0'}}>مجموع الاشياء</h3>
            <h3 style={{ margin: '0'}}>Item Total</h3>
            </div>
            <div style={{ marginBottom: '1rem' }}>
            <h3 style={{ margin: '0'}}>ضريبة القيمة المضافة</h3>
            <h3 style={{ margin: '0'}}>VAT</h3>
            </div>
            <div style={{ marginBottom: '1rem' }}>
            <h3 style={{ margin: '0'}}>المجموع الإجمالي</h3>
            <h3 style={{ margin: '0'}}>Grand Total</h3>
            </div>
          </Box>
        </Box>

        <Divider
          sx={{ background: "#808b97", width: "100%", margin: "5px 0px" }}
        />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img
            className="vat-qr"
            src={invoiceData?.qr}
            style={{ objectFit: "contain" }}
          />
        </Box>
      </Grid>
    </Box>
  );
});

export default SimpleInvoice;
