import React, { forwardRef, useEffect, useContext, useState } from "react";
import Logo from "../images/logoCrop.png";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { AppStorage } from "../Utils/Context/AppContext";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils";
import moment from "moment";

const InvoiceTemplate = forwardRef(({type, flag}, ref) => {
  console.log(type);

  const { id } = useParams();
  const AppContext = useContext(AppStorage);
  const [invoiceData, setInvoiceData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [templateData, setTemplateData] = useState([]);

  function getPrintInvoice() {
    Request.get(`/getPrintInvoice/${id}`)
      .then((result) => {
        setInvoiceData(result.data.invoice);
        setUserData(result.data.UserData);
        console.log("first", result.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function getTemplateData(invoiceType) {
    if (!invoiceType) {
      console.log("Invoice type is undefined");
      return;
    }

    console.log("Fetching template with name:", invoiceType);
    Request.get(`/getTemplate?name=${invoiceType}`)
      .then((result) => {
        // Parse the value if it's a JSON string
        const parsedTemplateValues = result?.data?.template_values.map(
          (component) => {
            let parsedValue = component.value;

            // Try to parse only if the value is a valid JSON string
            if (typeof component.value === "string") {
              try {
                parsedValue = JSON.parse(component.value);
              } catch (error) {
                // If parsing fails, keep the value as is
                parsedValue = component.value;
              }
            }

            return {
              ...component,
              value: parsedValue,
            };
          }
        );

        console.log("Parsed template values:", parsedTemplateValues);
        setTemplateData(parsedTemplateValues);
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  useEffect(() => {
    if (AppContext?.userAuthToken) {
      if (type) {
        getTemplateData(type);
      } else {
        console.log("Type is not available");
      }
    }
  }, [AppContext?.userAuthToken, type]);

  useEffect(() => {
    document.title = `${invoiceData?.companyName}_${invoiceData?.invoiceNumber}`;
  }, [invoiceData]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getPrintInvoice();
    }
  }, [AppContext?.userInfo, AppContext?.userAuthToken]);

  const totalVat = invoiceData?.invoiceproducts
    ?.reduce((accumulator, item) => accumulator + parseFloat(item?.vat || 0), 0)
    ;

  const totalVatAsNumber = parseFloat(totalVat);

  const totalExtraValues =
    invoiceData?.extravalues?.reduce(
      (sum, item) => sum + (item?.value || 0),
      0
    ) || 0;
  // const totalExtraValuesDisc = totalExtraValues - invoiceData?.discount;
  const netAmount = AppContext?.userInfo?.addDiscount === true
      ? (invoiceData?.totalAfterVat)
      : (invoiceData?.vat != null ? invoiceData?.vat : (invoiceData?.total + totalVatAsNumber));

      
  return (
    <Box sx={{ backgroundColor: "#fff" }} ref={ref}>
      <Grid container spacing={1} sx={{ width: "100%", padding: "2px 32px" }}>
        {!!templateData.length &&
          templateData
            .sort((a, b) => a.indexNo - b.indexNo)
            .map((data, i) => (
              <Grid key={i} item xs={data.size} sx={{ width: "100%" }}>
                {data.type === "text" &&
                  Array.isArray(data.value) &&
                  data.value.map((field, index) => (
                    <React.Fragment key={index}>
                      {field?.value === "companyNumberAr" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.companyNumber}
                          </span>{" "}
                          {field?.label && ` :${field.label}`}
                        </h3>
                      )}
                      {field?.value === "companyNumber" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.companyNumber}
                          </span>
                        </h3>
                      )}
                      {field?.value === "companyName" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.companyName}
                          </span>
                        </h3>
                      )}
                      {field?.value === "companyNameAr" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.companyNameAR}
                          </span>
                          {field?.label && ` :${field.label}`}
                        </h3>
                      )}
                      {field?.value === "vatNumber" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.vatNumber}
                          </span>
                        </h3>
                      )}
                      {field?.value === "vatNumberAr" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.vatNumber}
                          </span>
                          {field?.label && ` :${field.label}`}
                        </h3>
                      )}
                      {field?.value === "titleTaxInvoice" && (
                        <h2
                          style={{
                            textAlign: data?.align,
                            margin: "unset",
                            lineHeight: 1,
                            fontWeight: data?.fontWeightValue,
                          }}
                        >
                          {field?.label && field?.label !== ""
                            ? field?.label
                            : flag === false
                            ? `TAX INVOICE – فاتورة ضريبية`
                            : `Price Offer - عرض سعر`}
                        </h2>
                      )}
                      {flag === false && field?.value === "saudiRiyals" && (
                        <p
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeightValue,
                          }}
                        >
                          {field?.label && field?.label !== ""
                            ? field?.label
                            : `Saudi Riyals-ريال سعودي`}
                        </p>
                      )}
                      {flag === false && field?.value === "userAddress" && (
                        <p
                          style={{
                            marginBottom: "unset",
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {userData?.address}
                          </span>
                        </p>
                      )}
                      {field?.value === "space" && (
                        <div
                          style={{ width: "100%", textAlign: data?.align }}
                        ></div>
                      )}
                      {field?.value === "hr" && (
                        <Box
                          sx={{
                            borderBottom: "2px dashed black",
                            width: "100%",
                            marginTop: "30px",
                            marginBottom: "30px",
                            textAlign: data?.align,
                          }}
                        ></Box>
                      )}
                      {field?.value === "text" &&
                        invoiceData?.bank_iban != null && (
                          <table
                            style={{
                              fontSize: "14px",
                              width: "100%",
                              border: "1px solid black",
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  padding: "25px",
                                  fontWeight: data?.fontWeight,
                                }}
                              >
                                {field?.label && `${field.label}: `}
                                <span
                                  style={{ fontWeight: data?.fontWeightValue }}
                                >
                                  {invoiceData?.bank_iban}
                                </span>
                              </td>
                            </tr>
                          </table>
                        )}
                      {/* Info Table Text */}
                      {field?.value === "customerAddress" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.address}
                          </span>
                        </h3>
                      )}
                      {field?.value === "customerName" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.companyName}
                          </span>
                        </h3>
                      )}
                      {field?.value === "invoiceDate" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label}:{" "}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {moment(invoiceData?.invoiceDate).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </h3>
                      )}
                      {field?.value === "invoiceNo" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {flag === false
                              ? invoiceData?.invoiceNumber
                              : invoiceData?.offerNumber}
                          </span>
                        </h3>
                      )}
                      {field?.value === "notes" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.notes}
                          </span>
                        </h3>
                      )}
                      {flag === true && field?.value === "validity" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.validity}
                          </span>
                        </h3>
                      )}
                      {field?.value === "notes2" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.notes_2}
                          </span>
                        </h3>
                      )}
                      {flag === false && field?.value === "poNo" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.po}
                          </span>
                        </h3>
                      )}
                      {flag === false && field?.value === "paymentTerms" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.payment}
                          </span>
                        </h3>
                      )}
                      {field?.value === "vatNo" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.vatNumber}
                          </span>
                        </h3>
                      )}
                      {/* Total table Text */}
                      {field?.value === "totalBeforeVat" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.total}
                          </span>
                        </h3>
                      )}
                      {field?.value === "discount" &&
                        AppContext?.userInfo?.addDiscount === true && (
                          <h3
                            style={{
                              textAlign: data?.align,
                              fontWeight: data?.fontWeight,
                            }}
                          >
                            {field?.label && `${field.label}: `}
                            <span style={{ fontWeight: data?.fontWeightValue }}>
                              {invoiceData?.discount}
                            </span>
                          </h3>
                        )}
                      {field?.value === "netAmount" && (
                        <h3
                          style={{
                            textAlign: data?.align,
                            fontWeight: data?.fontWeight,
                          }}
                        >
                          {field?.label && `${field.label}: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {netAmount}
                          </span>
                        </h3>
                      )}
                      {field?.value === "totalAfterDiscount" &&
                        AppContext?.userInfo?.addDiscount === true && (
                          <h3
                            style={{
                              textAlign: data?.align,
                              fontWeight: data?.fontWeight,
                            }}
                          >
                            {field?.label && `${field.label}: `}
                            <span style={{ fontWeight: data?.fontWeightValue }}>
                              {invoiceData?.totalAfterDiscount}
                            </span>
                          </h3>
                        )}
                      {field?.value === "vat" && (
                        <h3 style={{ textAlign: data?.align }}>
                          {field?.label &&
                            `${field.label}${AppContext?.userInfo?.vat}%: `}
                          <span style={{ fontWeight: data?.fontWeightValue }}>
                            {invoiceData?.vat != null  ? invoiceData?.vat : invoiceData?.invoiceproducts
                              ?.map((item) => +item?.vat)
                              .reduce((acc, vat) => acc + vat, 0)}
                          </span>
                        </h3>
                      )}
                    </React.Fragment>
                  ))}

                {/* Info Table */}
                {data.type === "table" && data?.tableType === "Info Table" && (
                  <table
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      pageBreakAfter: "auto",
                    }}
                  >
                    {data.value
                      .reduce((rows, title, index, array) => {
                        if (index % 2 === 0) {
                          rows.push(array.slice(index, index + 2));
                        }
                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((title, colIndex) => (
                            <React.Fragment key={colIndex}>
                              <td
                                className="thInvoice"
                                style={{ fontWeight: data?.fontWeight }}
                              >
                                {title?.label}
                              </td>
                              <td className="tdInvoice">
                                {title?.value === "invoiceDate" &&
                                  moment(invoiceData?.invoiceDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                {title?.value === "invoiceNo" &&
                                  flag === false &&
                                  invoiceData?.invoiceNumber}
                                {title?.value === "invoiceNo" &&
                                  flag === true &&
                                  invoiceData?.offerNumber}
                                {title?.value === "customerName" &&
                                  invoiceData?.companyName}
                                {title?.value === "customerAddress" &&
                                  invoiceData?.address}
                                {title?.value === "vatNo" &&
                                  invoiceData?.vatNumber}
                                {title?.value === "notes" && invoiceData?.notes}
                                {title?.value === "notes2" &&
                                  invoiceData?.notes_2}
                                {title?.value === "poNo" && invoiceData?.po}
                                {flag === false &&
                                title?.value === "paymentTerms" &&
                                invoiceData?.payment ? (
                                  <>{invoiceData.payment}</>
                                ) : null}
                              </td>
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                  </table>
                )}
                {/* Product Table */}
                {data.type === "table" &&
                  data?.tableType === "Product Table" && (
                    <table
                      style={{
                        width: "100%",
                      }}
                      className="printTable"
                    >
                      <tr>
                        {data?.value.map((title, i) => (
                          <th
                            key={i}
                            className="thInvoice"
                            style={{ fontWeight: data?.fontWeight }}
                          >
                            {title?.label}
                          </th>
                        ))}
                      </tr>
                      {!!invoiceData?.invoiceproducts?.length &&
                        invoiceData.invoiceproducts.map((item, i) => (
                          <tr key={i}>
                            {data.value.map((title, index) => (
                              <td
                                key={index}
                                className="tdInvoice"
                                style={{ textAlign: "center" }}
                              >
                                {title?.value === "srNo"
                                  ? i + 1
                                  : title?.value === "productName"
                                  ? item.productName
                                  : title?.value === "description"
                                  ? item.description
                                  : title?.value === "unit"
                                  ? item.unit
                                  : title?.value === "qty"
                                  ? item.qty
                                  : title?.value === "unitPrice"
                                  ? item.unitPrice
                                  : title?.value === "itemTotal"
                                  ? item.total
                                  : title?.value === "itemDiscount" &&
                                    AppContext?.userInfo?.addDiscount === true
                                  ? item.discount
                                  : title?.value === "itemTotalAfterDiscount" &&
                                    AppContext?.userInfo?.addDiscount === true
                                  ? item?.totalAfterDiscount
                                  : title?.value === "itemVat"
                                  ? item?.vat
                                  : title?.value === "itemTotalAfterVat"
                                  ? AppContext?.userInfo?.addDiscount === true
                                    ? item?.totalAfterVat
                                    : item?.total + item?.vat
                                  : ""}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </table>
                  )}
                {/* Total Table */}
                {data.type === "table" && data?.tableType === "Total Table" && (
                  <table
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      pageBreakBefore: "auto",
                      marginTop: "38px",
                    }}
                  >
                    <tbody>
                      {data.value.map((title, index) => {
                        const isExtraValues = title?.value === "extraValues";

                        return (
                          <React.Fragment key={index}>
                            {!isExtraValues && (
                              <tr>
                                <td
                                  className="thInvoice"
                                  style={{ fontWeight: data?.fontWeight }}
                                >
                                  {title?.label}
                                </td>
                                <td className="tdInvoice">
                                  {title?.value === "totalBeforeVat" &&
                                    invoiceData?.total}

                                  {title?.value === "discount" &&
                                    AppContext?.userInfo?.addDiscount ===
                                      true &&
                                    invoiceData?.discount}
                                  {title?.value === "totalAfterDiscount" &&
                                    AppContext?.userInfo?.addDiscount ===
                                      true &&
                                    invoiceData?.totalAfterDiscount}
                                  {title?.value === "vat"
                                    ? invoiceData?.vat != null
                                      ? invoiceData?.vat
                                      : invoiceData?.invoiceproducts?.reduce(
                                          (accumulator, item) =>
                                            accumulator + (+item?.vat || 0),
                                          0
                                        )
                                    : null}

                                  {title?.value === "netAmount" && netAmount}
                                </td>
                              </tr>
                            )}
                            {isExtraValues &&
                              invoiceData?.extravalues?.length > 0 &&
                              invoiceData.extravalues.map((item, i) => (
                                <tr key={i}>
                                  <td
                                    className="thInvoice"
                                    style={{ fontWeight: data?.fontWeight }}
                                  >
                                    {item.text}:
                                  </td>
                                  <td className="tdInvoice">{item.value}</td>
                                </tr>
                              ))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {/* image data */}
                {data.type === "image" && data.value === "qr" && (
                  <div style={{ textAlign: data?.align }}>
                    <img
                      className="vat-qr"
                      src={invoiceData?.qr}
                      style={{ objectFit: "contain", marginTop: "25px" }}
                    />
                  </div>
                )}
                {data.type === "image" && data.value === "logo" && (
                  // <div style={{ textAlign: data?.align }}>
                  <img
                    style={{ width: "100%" }}
                    src={
                      userData?.logo !== "" &&
                      userData?.logo !== null &&
                      userData?.logo
                        ? `https://api.doryalinvoices.com/image-storage/${userData?.logo}`
                        : Logo
                    }
                    alt=""
                  />
                  // </div>
                )}
              </Grid>
            ))}
      </Grid>
    </Box>
  );
});
export default InvoiceTemplate;
