import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Navigate, Route, Routes } from "react-router-dom";
import AllUsers from "../components/AllUsers";
import AllSubAdmin from "../components/AllSubAdmin";
import AllInvoices from "../components/AllInvoices";
import SideBarList from "../components/SideBarList";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import UpdateProfile from "../components/UpdateProfile";
import AllClients from "../components/AllClients";
import AllProducts from "../components/AllProducts";
import userImg from "../images/user.png";
import AddInvoice from "../components/AddInvoice";
import PrintComponent from "../components/PrintComponent";
import Logo from "../images/side-logo.png";
import Backdrop from "@mui/material/Backdrop";
import axios from "../Utils/Request/axios";
import { FormControl, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import AllOfferPrice from "../components/AllOfferPrice";
import AllSalesReturn from "../components/AllsalesReturn";
import AllSuppliers from "../components/AllSuppliers";
import AllPurchases from "../components/AllPurchases";
import AddPurchase from "../components/AddPurchase";
import TemplateDesign from "../components/TemplateDesign";
import TemplateList from "../components/TemplateLists";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Dashboard() {
  const AppContext = useContext(AppStorage);
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleCloseBackdrop();
  };

  // sub menu for logout ....
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    handleClose();
    AppContext?.setUserToken("");
    localStorage.removeItem("p_u_t");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("selectedLanguage");
    delete axios.defaults.headers.common.Authorization;
    window.location.reload();
  }
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // backdrop
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };
  function menuOpen() {
    handleDrawerOpen();
    handleToggle();
  }
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);

    localStorage.setItem("selectedLanguage", languageValue);
  };
  return (
    <div className="dashboard">
      <Box sx={{ display: "flex", background: "#f3f3f4" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: "#365b6d",
          }}
        >
          <Toolbar sx={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={menuOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {AppContext?.userInfo?.role === "user" && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControl
                  sx={{
                    m: 1,
                    minWidth: 140,
                    // backgroundColor: "#fff",
                    "& fieldset": {
                      borderColor: "#fff !important",
                      borderRadius: "none",
                      "& legend": {
                        "& span": {
                          color: "#fff !important",
                        },
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff",
                    },
                    "& .MuiInputBase-formControl": {
                      borderRadius: "unset !important",
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{
                      height: "40px",
                      color: "#fff",
                      "& svg": { fill: "#fff" },
                    }}
                    value={i18n.language}
                    onChange={changeLanguageHandler}
                  >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"ar"}>Arabic</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", gap: "15px" }}>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img
                    className="imgT"
                    src={
                      AppContext?.userInfo.logo !== "" &&
                      AppContext?.userInfo.logo !== null &&
                      AppContext?.userInfo.logo
                        ? `https://api.doryalinvoices.com/image-storage/${AppContext?.userInfo.logo}`
                        : userImg
                    }
                    alt="N/A"
                  />
                </Button>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ marginTop: "40px" }}
              >
                <MenuItem
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    color: "#808b97",
                    fontSize: "13px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  {t("Logged In as")}:&nbsp;
                  <span style={{ textTransform: "capitalize" }}>
                    {AppContext?.userInfo.fullName}
                  </span>
                </MenuItem>
                <Divider className="diviDer" />
                <Link to={"/profile"} style={{ color: "black" }}>
                  <MenuItem>{t("Profile")}</MenuItem>
                </Link>
                <MenuItem onClick={logout}>{t("Logout")}</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ "& .MuiDrawer-paper": { backgroundColor: "#808b97" } }}
        >
          <DrawerHeader>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "3px",
              }}
            >
              <img
                src={Logo}
                alt=""
                style={{
                  width: "150px",
                  objectFit: "cover",
                  marginRight: "18px",
                }}
              />
            </Box>
            {/* </Typography> */}
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon sx={{ color: "#f3f3f4" }} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List onClick={handleDrawerClose}>
            <SideBarList open={handleDrawerOpen} />
            <Backdrop
              className="backdrop"
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                left: "240px",
              }}
              open={openBackdrop}
              onClick={handleDrawerClose}
            ></Backdrop>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {/* {AppContext?.userAuthToken || AppContext?.userAuthToken !== "" && ( */}
          <Routes>
            <Route path="/invoices" element={<AllInvoices />} />
            <Route path="/allOfferPrice" element={<AllOfferPrice />} />
            <Route path="/allSupplierInvoice" element={<AllPurchases />} />
            <Route path="/allSalesReturn" element={<AllSalesReturn />} />
            <Route path="/allUsers" element={<AllUsers />} />
            <Route path="/allSubAdmin" element={<AllSubAdmin />} />
            <Route path="/profile" element={<UpdateProfile />} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/allClients" element={<AllClients />} />
            <Route path="/allSuppliers" element={<AllSuppliers />} />
            <Route path="/invoice/:id" element={<PrintComponent />} />
            {/* <Route path="/invoice_custom/:id" element={<PrintComponent />} /> */}
            <Route
              path="/updateInvoice/:id"
              element={<AddInvoice flag={false} />}
            />
            <Route
              path="/duplicateInvoice/:id"
              element={<AddInvoice flag={false} duplicate={true} />}
            />
            <Route
              path="/duplicateOfferPrice/:id"
              element={<AddInvoice flag={true} duplicate={true} />}
            />
            <Route
              path="/updateOfferPrice/:id"
              element={<AddInvoice flag={true} />}
            />
            <Route
              path="/updateSupplierInvoice/:id"
              element={<AddPurchase />}
            />
            <Route
              path="/duplicateSupplierInvoice/:id"
              element={<AddPurchase duplicate={true}/>}
            />
            <Route path="/template-design/:id" element={<TemplateDesign />} />
            <Route path="/template-duplicate/:id" element={<TemplateDesign duplicate={true}/>} />
            <Route path="/template-design" element={<TemplateDesign />} />
            <Route path="/templateList" element={<TemplateList />} />

            {userInfo?.role === "user" && (
              <>
                <Route
                  path="/addInvoice"
                  element={<AddInvoice flag={false} />}
                />
                <Route
                  path="/offerPrice"
                  element={<AddInvoice flag={true} />}
                />
                <Route path="/addPurchase" element={<AddPurchase />} />
              </>
            )}
            <Route path="*" element={<Navigate to={"/invoices"} />} />
          </Routes>

          {/* ) } */}
        </Box>
      </Box>
    </div>
  );
}

export default Dashboard;
