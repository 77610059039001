import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import TemplateText from "./TemplateText";
import TemplateImage from "./TemplateImage";
import TemplateTable from "./TemplateTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Request from "../Utils/Request/Request";
import { showToast } from "../Utils";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ComponentTypes = {
  COMPONENT: "component",
};

const DraggableComponent = ({component, index, moveComponent, ...props }) => {
  const [, ref] = useDrag({
    type: ComponentTypes.COMPONENT,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ComponentTypes.COMPONENT,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveComponent(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} {...props}>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "4px",
          position: "relative",
          background: "#fff",
        }}
      >
        {component.type === "text" && (
          <TemplateText
            value={component.value}
            onChange={(value) => props.updateComponent(index, value)}
            // onComponentChange={(value) => props.changeComponentType(index, value)}
            
          />
        )}
        {component.type === "image" && (
          <TemplateImage
            value={component.value}
            onChange={(value) => props.updateComponent(index, value)}
          />
        )}
        {component.type === "table" && (
          <TemplateTable
            data={component}
            onChange={(data) => props.updateComponentData(index, data)}
            tableType={component.tableType}
          />
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
            mt: 2,
          }}
        >
          <Box>
            <Typography variant="body2" mr={1}>
              Size:
            </Typography>
            <Select
              value={component.size}
              onChange={(e) => props.updateComponentSize(index, e.target.value)}
            >
              {[...Array(12)].map((_, i) => (
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography variant="body2" mr={1}>
              Align:
            </Typography>
            <Select
              value={component.align}
              onChange={(e) =>
                props.updateComponentData(index, { align: e.target.value })
              }
            >
              <MenuItem value="right">Right</MenuItem>
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="left">Left</MenuItem>
            </Select>
          </Box>
          <Box>
            <Typography variant="body2" mr={1}>
              Font Title:
            </Typography>
            <Select
              value={component.fontWeight}
              onChange={(e) =>
                props.updateComponentData(index, { fontWeight: e.target.value })
              }
            >
              <MenuItem value="bold">Bold</MenuItem>
              <MenuItem value="normal">Normal</MenuItem>
            </Select>
          </Box>
          <Box>
            <Typography variant="body2">Font Value:</Typography>
            <Select
              value={component.fontWeightValue}
              onChange={(e) =>
                props.updateComponentData(index, {
                  fontWeightValue: e.target.value,
                })
              }
            >
              <MenuItem value="bold">Bold</MenuItem>
              <MenuItem value="normal">Normal</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <Button
            onClick={() => props.removeComponent(index)}
            sx={{ color: "red" }}
          >
            <DeleteIcon />
          </Button>
        </Box>
      </Box>
    </div>
  );
};

const TemplateDesign = ({ duplicate }) => {
  const { id } = useParams(); // Get ID from URL parameters
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState("");
  const [components, setComponents] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchTemplate = async () => {
        try {
          const response = await Request.get(`/getTemplateById/${id}`);
          console.log("d", response.data.template_values);
          const sortedComponents = response.data.template_values
            .map((component) => ({
              ...component,
              value: typeof component.value === "string"
              ? JSON.parse(component.value)
              : component.value,
          }))
            .sort((a, b) => a.indexNo - b.indexNo);

          setTemplateName(response.data.name);
          setComponents(sortedComponents);
          setTemplateId(response.data.id);
        } catch (error) {
          console.error("Error fetching template: ", error);
        }
      };

      fetchTemplate();
    }
  }, [id]);

  const addComponent = (type) => {
    setComponents((prevComponents) => {
      const emptyIndex = prevComponents.findIndex((c) => c.type === null);
      const newComponent = {
        type,
        value: [],
        size: emptyIndex !== -1 ? prevComponents[emptyIndex].size : 3,
        tableType: "",
        indexNo: emptyIndex === -1 ? prevComponents.length : emptyIndex,
        align: "right",
        fontWeight: "normal",
        fontWeightValue: "normal",
      };

      if (emptyIndex !== -1) {
        // Replace the empty component slot
        const updatedComponents = [...prevComponents];
        updatedComponents[emptyIndex] = newComponent;
        return updatedComponents;
      } else {
        // Add a new component if no empty slot is found
        return [...prevComponents, newComponent];
      }
    });
  };

  const updateComponent = (index, value) => {
    const newComponents = [...components];
    newComponents[index].value = value;
    setComponents(newComponents);
  };
  const updateComponentSize = (index, size) => {
    const newComponents = [...components];
    newComponents[index].size = size;
    setComponents(newComponents);
  };
  const updateComponentData = (index, data) => {
    const newComponents = [...components];
    newComponents[index] = { ...newComponents[index], ...data };
    setComponents(newComponents);
  };

  const removeComponent = (index) => {
    setComponents((prevComponents) => {
      const newComponents = [...prevComponents];
      newComponents[index] = { ...newComponents[index], type: null, value: [] }; // Retain size
      return newComponents;
    });
  };

  const validateComponents = () => {
    for (const component of components) {
      if (component.value === "" || component.value.length === 0) {
        return false;
      }
    }
    return true;
  };

  const saveTemplate = async () => {
    let rearrangedComponents;

    setComponents((prevComponents) => {
      // Filter out components with type === null
      const filteredComponents = prevComponents.filter((c) => c.type !== null);
      rearrangedComponents = filteredComponents.map((component, index) => ({
        ...component,
        indexNo: index,
      }));

      return rearrangedComponents;
    });
    if (!templateName.trim()) {
      showToast("error", "Template name cannot be empty.");
      return;
    }

    if (!validateComponents()) {
      showToast(
        "error",
        "All components must have type, value, and size filled out."
      );
      return;
    }
    if (rearrangedComponents.length < 1) {
      showToast("error", "At least one component is required.");
      return;
    }
    setIsSubmitting(true);
    const formattedComponents = rearrangedComponents.map((component) => ({
      indexNo: component.indexNo,
      type: component.type,
      // value: component.value,
      value: component.value, // Only save the value
      size: component.size,
      tableType: component.tableType,
      align: component.align,
      fontWeight: component.fontWeight,
      fontWeightValue: component.fontWeightValue,
    }));

    const endpoint =
      templateId && duplicate != true ? "/updateTemplate" : "/saveTemplate";
    const payload = templateId
      ? { id: templateId, name: templateName, components: formattedComponents }
      : { name: templateName, components: formattedComponents };
    console.log("gg", payload);

    try {
      await Request.post(endpoint, payload);
      setIsSubmitting(false);
      showToast("success", "Template Saved.");
      navigate("/templateList");
    } catch (err) {
      setIsSubmitting(false);
      console.log("Error: ", err);
      if (err.response.status === 401) {
        showToast("error", "Unauthorized user.");
      } else if (err.response.status === 409) {
        showToast("error", err.response.data.error);
      } else {
        showToast("error", "Something went wrong.");
      }
    }
  };
  const changeComponentType = (index, newType) => {
    setComponents((prevComponents) => {
      const updatedComponents = [...prevComponents];
      const currentComponent = updatedComponents[index];

      if (currentComponent.type !== newType) {
        currentComponent.type = newType;
        currentComponent.value = []; // Reset value when type changes
      }

      return updatedComponents;
    });
  };
  // const moveComponent = (fromIndex, toIndex) => {
  //   const updatedComponents = [...components];
  //   const [movedComponent] = updatedComponents.splice(fromIndex, 1);
  //   updatedComponents.splice(toIndex, 0, movedComponent);
  //   setComponents(updatedComponents);
  // };
  const moveComponent = (fromIndex, toIndex) => {
    const updatedComponents = [...components];
    const [movedComponent] = updatedComponents.splice(fromIndex, 1);
    updatedComponents.splice(toIndex, 0, movedComponent);

    updatedComponents.forEach((component, idx) => {
      component.indexNo = idx;
    });

    setComponents(updatedComponents);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ px: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <TextField
            label="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            sx={{ flexGrow: 1, mr: 2 }}
          />
          <Button
            onClick={saveTemplate}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Save Template
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={() => addComponent("text")}>
            Add Text
          </Button>
          <Button variant="outlined" onClick={() => addComponent("image")}>
            Add Image
          </Button>
          <Button variant="outlined" onClick={() => addComponent("table")}>
            Add Table
          </Button>
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {components.map((component, index) => (
            <Grid item xs={component.size} key={index}>
              {component.type && (
                <DraggableComponent
                  component={component}
                  index={index}
                  moveComponent={moveComponent}
                  updateComponent={updateComponent}
                  updateComponentData={updateComponentData}
                  removeComponent={removeComponent}
                  updateComponentSize={updateComponentSize}
                  changeComponentType={changeComponentType}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </DndProvider>
  );
};

export default TemplateDesign;
