import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Request from "../Utils/Request/Request";
import { useState, useContext, useEffect, useRef } from "react";
import { AppStorage } from "../Utils/Context/AppContext";
import { showToast } from "../Utils/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "./Loader";
import DialogProducts from "./DialogProducts";
import { useTranslation } from "react-i18next";
import DuplicateModal from "../modals/DuplicateModal";

function AllPurchases() {
  const navigate = useNavigate();
  const [openInvoiceProducts, setOpenInvoiceProducts] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [dupInvoice, setDupInvoice] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const { t, i18n } = useTranslation();
  const handleOpenInvoiceProducts = (value) => {
    setOpenInvoiceProducts(true);
    setSelectedItem(value);
  };

  const handleCloseInvoiceProducts = () => {
    setOpenInvoiceProducts(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);

  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelInvoice(user);
  };

  const CloseDelModal = () => {
    setOpenDelModal(false);
  };
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [delInvoice, setDelInvoice] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [vatInvoices, setVatInvoices] = useState([]);
  const [vatPurchase, setVatPurchase] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 7,
  });
  const [searchPhrase, setSearchPhrase] = useState("");

  const AppContext = useContext(AppStorage);

  useEffect(() => {
    if (AppContext?.userInfo.role && AppContext?.userInfo.role != "user") {
      setTableColumns(columns.concat(userColumn, actionColumn));
    } else {
      setTableColumns(columns.concat(actionColumn));
    }
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getData({ pagination, searchPhrase });
      getTotalVat({ startDate, endDate });
    }
  }, [
    AppContext?.userAuthToken,
    pagination?.current,
    AppContext?.userInfo,
    i18n.language,
  ]);

  useEffect(() => {
    if (AppContext?.userInfo?.role === "admin") {
      i18n.changeLanguage("en");
    }
  }, [
    AppContext?.userAuthToken,
    pagination?.current,
    AppContext?.userInfo,
    i18n.language,
  ]);

  useEffect(() => {
    if (forceUpdate) {
      getData({ pagination, startDate, endDate });
      getTotalVat({ startDate, endDate });
    }
  }, [forceUpdate]);


  function getTotalVat(params) {
    console.log("yourrrr")
    setLoading(true);
    let paramsData = {};
    paramsData = {
      ...paramsData,
      startDate:
        params?.dateStart ||
        moment.utc(moment(startDate).format().substring(0, 10)).format(),
      endDate:
        params?.dateEnd ||
        moment.utc(moment(endDate).format().substring(0, 10)).format(),
    };

    Request.get(`/getTotalVat`, paramsData)
      .then((result) => {
        setLoading(false);
        console.log("hello", result.data?.data);
        
        const filteredPurchase = result.data?.data.filter(
          (invoice) => invoice.supplierInvoice === true
        );
        setVatPurchase(filteredPurchase);

        const filteredInvoice = result.data?.data.filter(
          (invoice) => invoice.supplierInvoice === false
        );
        
        setVatInvoices(filteredInvoice);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getData(params) {
    setLoading(true);
    let paramsData = {};

    paramsData = {
      ...paramsData,
      startDate:
        params?.dateStart ||
        moment.utc(moment(startDate).format().substring(0, 10)).format(),
      endDate:
        params?.dateEnd ||
        moment.utc(moment(endDate).format().substring(0, 10)).format(),
      searchPhrase: params.searchPhrase,
      supplierInvoice: true,
    };

    Request.get(`/getInvoice/${params.pagination?.current}`, paramsData)
      .then((result) => {
        console.log("nsdaj", result.data?.data);
        setLoading(false);
        setInvoices(result.data?.data);
        setForceUpdate(false);
        setPagination({
          ...params.pagination,
          total: result.data["totalCount"],
        });
        setSearchPhrase(params.searchPhrase);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const handleSelect = (date) => {
    const dateStart = moment
      .utc(moment(date.selection.startDate).format().substring(0, 10))
      .format();
    const dateEnd = moment
      .utc(moment(date.selection.endDate).format().substring(0, 10))
      .format();

    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);

    getData({ pagination, dateStart, dateEnd });
    getTotalVat({ dateStart, dateEnd });
  };
  const handleChange = (event, value) => {
    let dupPagination = { ...pagination };
    dupPagination.current = value;
    setPagination(dupPagination);
  };


  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  // Function to recalculate row numbers
  const updateRowNumbers = () => {
    const invoicesWithRowNumber = invoices.map((invoice, index) => {
      return {
        ...invoice,
        rowNumber: (pagination.current - 1) * 7 + (index + 1),
      };
    });
    setInvoices(invoicesWithRowNumber);
  };

  // Function to handle row deletion
  const handleRowDelete = (selectedRowIds) => {
    const updatedInvoices = invoices.filter(
      (invoice) => !selectedRowIds.includes(invoice.id)
    );
    setInvoices(updatedInvoices);
    updateRowNumbers(); // Recalculate row numbers after deletion
  };

  const columns = [
    { field: "rowNumber", headerName: `${t("SR-NO.")}`, width: 20 },
    {
      field: "supplierNumber",
      headerName: `${t("Supplier Invoice No.")}`,
      width: 50,
    },
    { field: "supplierName", headerName: `${t("Supplier Name")}`, width:180 },
    { field: "vatNumber", headerName: `${t("Vat Number")}`, width: 100 },
    { field: "total", headerName: `${t("Total")}`, width: 70 },
    {
      field: "invoiceproducts",
      headerName: `${t("No. of Products")}`,
      renderCell: (text) => {
        return (
          <div>
            <Button
              variant="outlined"
              onClick={() => handleOpenInvoiceProducts(text)}
            >
              {text?.value?.length}
            </Button>
          </div>
        );
      },
      width: 100,
    },
    {
      field: "invoiceDate",
      headerName: `${t("Supplier Invoice Date")}`,
      renderCell: (text) => (
        <span>{moment.utc(text?.value).format("YYYY-MM-DD")}</span>
      ),
      width: 90,
    },
  ];

  let actionColumn = [
    {
      headerName: `${t("Actions")}`,
      width: 150,
      renderCell: (record) => {
        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            {(AppContext?.userInfo.role === "admin" ||
              AppContext?.userInfo.role === "user") && (
              <PdfIcon
                sx={{ cursor: "pointer", color: "#FF0000" }}
                onClick={() => printInvoice(record.row)}
              />
            )}
            {AppContext?.userInfo.role === "user" && (
              <EditIcon
                onClick={() => UpdateClientModal(record.row)}
                // console.log(record.row)
                sx={{ cursor: "pointer", color: "#41c1ba" }}
              />
            )}

            {AppContext?.userInfo.role === "user" && (
              <DeleteIcon
                sx={{ cursor: "pointer", color: "#365b6d" }}
                onClick={() => OpenDelModal(record.row)}
              />
            )}
            {(AppContext?.userInfo.role === "user") && (
            <ContentCopyIcon
              sx={{ cursor: "pointer", color: "#365b6d" }}
              onClick={() => duplicatePurchase(record?.row)} 
            />
            )}
          </Box>
        );
      },
    },
  ];

  let userColumn = [
    {
      field: "user",
      headerName: "User Name",
      renderCell: (text) => {
        return <div> {text.value.fullName}</div>;
      },
      width: 70,
    },
  ];

  function printInvoice(invoice) {
    window.open(`/invoice/${invoice.id}`, "_blank")?.focus();
  }

function duplicatePurchase(invoice) { 
  setOpenDuplicateModal(true)
  setDupInvoice(invoice)
}

function navigateDuplicatePurchase(invoice){
  navigate(`/duplicateSupplierInvoice/${invoice.id}`);
} 
  function UpdateClientModal(invoice) {
    navigate(`/updateSupplierInvoice/${invoice.id}`);
  }

  function deleteInvoice(invoice) {
    Request.delete(`/delInvoice/${invoice["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
      })
      .catch((err) => {
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const totalSumPurchaseBefore = vatPurchase.reduce(
    (acc, curr) => acc + curr.total,
    0
  );
  const totalSumSalesBefore = vatInvoices.reduce(
    (acc, curr) => acc + curr.total,
    0
  );
  const totalSumSalesAfter = vatInvoices.reduce(
    (acc, curr) => acc + curr.totalAfterVat,
    0
  );
  const totalSumPurchaseAfter = vatPurchase.reduce(
    (acc, curr) => acc + curr.totalAfterVat,
    0
  );
// Assuming vatInvoices is an array of objects, and each object has an invoiceproducts array
const totalSumSalesAfterVat = vatInvoices
  ?.map(invoice => invoice.invoiceproducts.reduce((acc, item) => acc + parseFloat(item?.vat || 0), 0))
  .reduce((acc, sum) => acc + sum, 0)
  .toFixed(2);

// Assuming vatPurchase is also an array of objects, and each object has an invoiceproducts array
const totalSumPurchaseAfterVat = vatPurchase
  ?.map(purchase => purchase.invoiceproducts.reduce((acc, item) => acc + parseFloat(item?.vat || 0), 0))
  .reduce((acc, sum) => acc + sum, 0)
  .toFixed(2);
  
  const totalSumSalesAfterDis = +totalSumSalesBefore + +totalSumSalesAfterVat
  const totalSumPurchaseAfterDis = +totalSumPurchaseBefore + +totalSumPurchaseAfterVat
  console.log("first", totalSumSalesAfterDis, totalSumPurchaseAfterDis);

  const totalVat = (totalSumSalesBefore - totalSumPurchaseBefore) * AppContext?.userInfo?.vat / 100 

  const normalizeDate = (date) => {
    const normalized = new Date(date);
    normalized.setUTCHours(0, 0, 0, 0);
    return normalized;
  };
  const accessDate =  AppContext?.userInfo?.purchaseSupplierAccess ? new Date(AppContext?.userInfo?.purchaseSupplierAccess) : null;
  const accessTotalDate =  AppContext?.userInfo?.showTotalCalculator ? new Date(AppContext?.userInfo?.showTotalCalculator) : null;
  const currentDate = normalizeDate(new Date()); 
  return (
    <Box>
      {(AppContext?.userInfo.role === "user" && accessDate === null || accessDate >= currentDate)  && (
        <Button
          className="smallScreen-btn"
          onClick={() => navigate(`/addPurchase`)}
          variant="contained"
          sx={{
            fontSize: "30px",
            background: "#365b6d",
            "&:hover": { backgroundColor: "#365b6d" },
            height: "40px",
            float: "right",
            marginRight: "12px",
            textAlign: "center",
          }}
        >
          +
        </Button>
      )}

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "0px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            onMouseOver={() => setShowDatePicker(true)}
            onMouseOut={() => setShowDatePicker(false)}
            sx={{
              width: "332px",
              position: "relative",
              "& .rdrDateRangePickerWrapper": {
                width: "100%",
                "& .rdrCalendarWrapper ": {
                  width: "100%",
                },
              },
              "& .rdrMonthAndYearWrapper": {
                position: "absolute",
                top: 70,
                background: "white",
                width: "100%",
                display: showDatePicker ? "" : "none",
                zIndex: 999,
                boxShadow: "0px 3px 8px rgb(100,100,100)",
              },
              "& .rdrMonths ": {
                position: "absolute",
                top: 130,
                background: "white",
                display: showDatePicker ? "" : "none",
                zIndex: 999,
                boxShadow: "0px 3px 8px rgb(100,100,100)",
              },
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
            />
          </Box>
        </Box>
        {(AppContext?.userInfo.role === "user" && accessDate === null || accessDate >= currentDate) && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              className="largeScreen-btn"
              onClick={() => navigate(`/addPurchase`)}
              variant="contained"
              sx={{
                background: "#365b6d",
                "&:hover": { backgroundColor: "#365b6d" },
                height: "40px",
              }}
            >
              {t("Add Purchase")}
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: "20px",
          "@media only screen and (max-width:1100px)": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            width: "40%",
            "@media only screen and (max-width:1100px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#365b6d", textAlign: "center" }}
          >
            {t("Total Calculated")}
          </Typography>
          <>
          {(accessTotalDate === null || accessTotalDate >= currentDate) ? (      
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              backgroundColor: "#fff",
              py: 1,
              px: 2,
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "40%",
              "@media only screen and (max-width:450px)": {
                  "& .salesB":{
                   fontSize:"12px",
                  }
                 },
               }}>
                <p className="salesB">Sales before Vat</p>
                <div
                  style={{
                    border: "1px solid #1976d2",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ textAlign: "center", padding: "10px 0px" }}>
                    {totalSumSalesBefore.toFixed(2)}
                  </p>
                </div>
              </Box>
              <Box sx={{ width: "40%",
              "@media only screen and (max-width:450px)": {
                  "& .purchaseB":{
                   fontSize:"12px",
                  }
                 },
               }}>
                <p className="purchaseB">Purchases before Vat</p>
                <div
                  style={{
                    border: "1px solid #1976d2",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ textAlign: "center", padding: "10px 0px" }}>
                    {totalSumPurchaseBefore.toFixed(2)}
                  </p>
                </div>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "40%",
                "@media only screen and (max-width:450px)": {
                 "& .salesA":{
                  fontSize:"12px",
                 }
                },
               }}>
                <p className="salesA">Sales after Vat</p>
                <div
                  style={{
                    border: "1px solid #1976d2",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ textAlign: "center", padding: "10px 0px" }}>
                    {AppContext?.userInfo?.addDiscount === true ?  totalSumSalesAfter.toFixed(2) : totalSumSalesAfterDis.toFixed(2)}
                  </p>
                </div>
              </Box>
              <Box sx={{ width: "40%",
                 "@media only screen and (max-width:450px)": {
                  "& .purchaseA":{
                   fontSize:"12px",
                  }
                 },
               }}>
                <p className="purchaseA">Purchases after Vat</p>
                <div
                  style={{
                    border: "1px solid #1976d2",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ textAlign: "center", padding: "10px 0px" }}>
                    {AppContext?.userInfo?.addDiscount === true ? totalSumPurchaseAfter.toFixed(2) : totalSumPurchaseAfter.toFixed(2)}
                  </p>
                </div>
              </Box>
            </Box>
            <h4 style={{ textAlign: "center", marginBottom: "6px" }}>
              Net vat is {totalVat}
            </h4>
          </Box>
          ) : (
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
              <p style={{color:"rgb(61 145 255)",textAlign:"center"}}>{t("Unfortunately, this service cannot be accessed. Contact the support person responsible for your account")}</p></div>
          )}
          </>
        </Box>

        <Box
          sx={{
            height: "calc(100vh - 235px)",
            width: "60%",

            "@media only screen and (max-width:1100px)": {
              width: "100%",
              my: 7,
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#365b6d", textAlign: "center" }}
          >
            {t("All Purchases")}
          </Typography>
          <>
          {(accessDate === null || accessDate >= currentDate) ? (
          loading ? (
            <Loader />
          ) : (
            <>
              <DataGrid
                // rows={invoices}
                rows={invoices.map((invoice, index) => ({
                  ...invoice,
                  rowNumber: (pagination.current - 1) * 7 + (index + 1),
                }))}
                columns={tableColumns}
                disableColumnMenu
                hideFooter
                checkboxSelection={false}
                disableSelectionOnClick
                sx={{ background: "white" }}
                onSelectionModelChange={(newSelection) =>
                  handleRowDelete(newSelection)
                }
              />
              <Stack spacing={2} marginTop="5px">
                <Pagination
                  count={Math.ceil(pagination?.total / 7)}
                  page={pagination?.current}
                  onChange={handleChange}
                />
              </Stack>
            </>
              )
            ) : (
              <div style={{display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
                <p style={{color:"rgb(61 145 255)", textAlign:"center"}}>{t("Unfortunately, this service cannot be accessed. Contact the support person responsible for your account")}</p></div>
            )}
          </>
        </Box>
      </Box>
      <DialogProducts
        handleCloseInvoiceProducts={handleCloseInvoiceProducts}
        openInvoiceProducts={openInvoiceProducts}
        text={selectedItem}
      />
      {openDelModal && (
        <DeleteModal
          del={deleteInvoice}
          currentUser={delInvoice}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}
      {openDuplicateModal && (
        <DuplicateModal
          currentUser={dupInvoice}
          openDuplicateModal={openDuplicateModal}
          setDuplicateModal={setOpenDuplicateModal}
          duplicateUser={navigateDuplicatePurchase}
        />
      )}
    </Box>
  );
}

export default AllPurchases;
